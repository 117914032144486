// Core Imports
import { Component, Injectable, OnInit } from '@angular/core';

// Provider Imports
import { LayerOperationOption } from '../interfaces';
import { ObjectModificationMenuService } from './object-modification-menu.service';
import { DefaultValueService } from './default-value.service';

// Vendor Imports
import { fabric } from 'fabric';
import FontFaceObserver from 'fontfaceobserver';
import * as fontFaceObserver from 'fontfaceobserver';

@Injectable()
export class ObjectModificationsService implements OnInit {

  constructor(private mis: ObjectModificationMenuService,
              private dvs: DefaultValueService
  ) {

  }

  ngOnInit() {

  }

  handleObjectOperation(canvas: fabric.Canvas, operationName: string, payload) {

    // console.log(operationName);

    const activeObject = canvas.getActiveObject();

    // Populate local storage with object-data whilst editing

    // Todo Save *original* dimensions

    localStorage.setItem('activeObjAttrs', JSON.stringify({
      width: activeObject.getScaledWidth(),
      height: activeObject.getScaledHeight()
    }));

    // Set Text
    if (operationName === 'set_text') {

      console.log(payload.target.value);
      // @ts-ignore
      activeObject.set('text', payload.target.value);
    }

    // Set filter
    if (operationName === 'set_filter') {

      this.applyFilterValue(activeObject, '', '');
    }

    // Set Letter Spacing
    if (operationName === 'set_letter_spacing') {

      // @ts-ignore
      activeObject.set('charSpacing', payload.value);
    }

    // Set Object Text Color (TODO maybe rename fo fill for cliparts)
    if (operationName === 'set_fill') {

      if (activeObject.type === 'clipArt') {

        // @ts-ignore
        activeObject.getObjects().forEach(object => {

          object.set('fill', payload.value);
        });
      } else {

        // @ts-ignore
        activeObject.set('fill', payload.value);
      }
    }

    // Text Align
    if (operationName === 'set_text_align') {

      // @ts-ignore
      activeObject.set('textAlign', payload.value);
    }

    // Set Outline Width
    if (operationName === 'set_outline') {

      if (activeObject.type === 'clipArt') {

        // @ts-ignore
        activeObject.getObjects().forEach(object => {

          object.set('strokeWidth', payload.value);
        });
      } else {

        // @ts-ignore
        activeObject.set('strokeWidth', payload.value);
      }
    }

    // Set Outline Color
    if (operationName === 'set_stroke_color') {

      if (activeObject.type === 'clipArt') {

        // @ts-ignore
        activeObject.getObjects().forEach(object => {

          object.set('stroke', payload.value);
        });
      } else {

        // @ts-ignore
        activeObject.set('stroke', payload.value);
      }
    }

    // Set Strikethru
    if (operationName === 'set_strikethru') {

      // @ts-ignore
      const currentState = activeObject.get('linethrough');

      // @ts-ignore
      activeObject.set('linethrough', !currentState);
    }

    // Set Underline
    if (operationName === 'set_underline') {

      // @ts-ignore
      const currentState = activeObject.get('underline');

      // @ts-ignore
      activeObject.set('underline', !currentState);
    }

    // Set Italic
    if (operationName === 'set_italic') {

      // @ts-ignore
      const currentState = activeObject.get('fontStyle');

      // @ts-ignore
      activeObject.set('fontStyle', currentState === 'normal' ? 'italic' : 'normal');
    }

    // Set Bold
    if (operationName === 'set_bold') {

      // @ts-ignore
      const currentState = activeObject.get('fontWeight');

      // @ts-ignore
      activeObject.set('fontWeight', currentState === 'bold' ? 'normal' : 'bold');
    }

    // Set Text Size
    if (operationName === 'increase_font_size') {

      // @ts-ignore
      activeObject.set('fontSize', activeObject.get('fontSize') + 12);
    }
    if (operationName === 'decrease_font_size') {

      // @ts-ignore
      activeObject.set('fontSize', activeObject.get('fontSize') - 12);
    }

    // Set Object Shadow

    // Set Font
    if (operationName === 'set_font') {

      this.loadAndUseFont(canvas, payload.value);
      this.dvs.setDefaultFont(payload.value);
    }

    // Cropping Options
    if (operationName === 'crop_to_circle') {

      activeObject.clipTo = function (ctx: CanvasRenderingContext2D) {
        ctx.arc(0, 0, 250, 0, Math.PI * 2, true);
      };
    }

    if (operationName === 'crop_to_16_9') {

      activeObject.clipTo = function (ctx: CanvasRenderingContext2D) {

        const x = JSON.parse(localStorage.getItem('activeObjAttrs')).width;
        const y = JSON.parse(localStorage.getItem('activeObjAttrs')).height;

        // buggy
        let croppedImageWidth = x < y ? x * 1.78 : x;
        let croppedImageHeight = x < y ? y * 1.78 : y;

        if (x === y) {

          croppedImageWidth = y * 1.78;
          croppedImageHeight = y;
        }

        ctx.rect(
          -(croppedImageWidth / 2),
          -(croppedImageHeight / 2),
          croppedImageWidth,
          croppedImageHeight
        );
      };
      activeObject.scaleToWidth(650);
    }

    if (operationName === 'crop_to_smartphone') {

      activeObject.clipTo = function (ctx: CanvasRenderingContext2D) {

        const x = JSON.parse(localStorage.getItem('activeObjAttrs')).width;
        const y = JSON.parse(localStorage.getItem('activeObjAttrs')).height;

        // ToDO!!!!!!
        let croppedImageWidth = x < y ? x * 1.78 : x;
        let croppedImageHeight = x < y ? y * 1.78 : y;

        console.log(x);
        console.log(y);

        if (x === y) {
          croppedImageWidth = x;
          croppedImageHeight = x * 1.78;
        }

        ctx.rect(
          -(croppedImageWidth / 2),
          -(croppedImageHeight / 2),
          croppedImageWidth,
          croppedImageHeight
        );
      };

      activeObject.scaleToWidth(650);
    }


    if (operationName === 'crop_to_square') {

      activeObject.clipTo = function (ctx: CanvasRenderingContext2D) {

        const x = JSON.parse(localStorage.getItem('activeObjAttrs')).width;
        const y = JSON.parse(localStorage.getItem('activeObjAttrs')).height;

        const croppedImageWidthAndHeight = x < y ? x : y ;

        ctx.rect(
          -(croppedImageWidthAndHeight / 2),
          -(croppedImageWidthAndHeight / 2),
          croppedImageWidthAndHeight,
          croppedImageWidthAndHeight
        );
      };

      activeObject.scaleToWidth(650);

    }
    if (operationName === 'crop_reset') {

      activeObject.clipTo = function (ctx: CanvasRenderingContext2D) {

        const croppedImageWidth = JSON.parse(localStorage.getItem('activeObjAttrs')).width;
        const croppedImageHeight = JSON.parse(localStorage.getItem('activeObjAttrs')).height;

        ctx.rect(
          -(croppedImageWidth / 2),
          -(croppedImageHeight / 2),
          croppedImageWidth,
          croppedImageHeight
        );
      };
      activeObject.scaleToWidth(activeObject.width);
    }

    // Set Diameter
    if (operationName === 'set_radius') {

      // @ts-ignore
      activeObject.set('diameter', payload.value);
    }

    // Set Kerning
    if (operationName === 'set_kerning') {

      // @ts-ignore
      activeObject.set('kerning', payload.value);
    }

    canvas.renderAll();
  }

  applyFilterValue(obj: fabric.Image | any, prop, value) {

/*    if (obj.filters[index]) {

      obj.filters[index][prop] = value;

      var timeStart = +new Date();
      obj.applyFilters();
      var timeEnd = +new Date();

      var dimString = canvas.getActiveObject().width + ' x ' +
        canvas.getActiveObject().height;

      $('bench').innerHTML = dimString + 'px ' +
        parseFloat(timeEnd-timeStart) + 'ms';
      canvas.renderAll();
    }*/

    const filter = new fabric.Image.filters.ColorMatrix({
      matrix: [
        .343, .669, .119, 0, 0,
        .249, .626, .130, 0, 0,
        .172, .334, .111, 0, 0,
        .000, .000, .000, .22, 0,
      ]
    });

    obj.filters.push(filter);
    obj.applyFilters();
  }

  loadAndUseFont(canvas: fabric.Canvas, font: string) {

    // @ts-ignore
    canvas.getActiveObject().set('fontFamily', font);
    canvas.requestRenderAll();

    // const newFont = new FontFaceObserver(font);

/*    newFont.load(font)
      .then(function () {

        // when font is loaded, use it.

        // @ts-ignore
        canvas.getActiveObject().set('fontFamily', font);
        canvas.requestRenderAll();

      }).catch(function (e) {

        console.log(e);
        alert('font loading failed ' + font);
    });*/
  }
}
