// Core Imports
import { Component, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Provider Imports

@Component({
  selector: '[text-layer]',
  styleUrls: ['text-layer.component.scss'],
  template: `
    
    <div class="text-layer" cdkDrag [formGroup]="pdfForm" (cdkDragEnded)="bums($event)">

        <div tabindex="-1" (focus)="handleFocus()" (blur)="handleFocus()" (dragstart)="handleFocus()" (dragend)="handleFocus()">

            <input type="text" style="text-align: center" [formControlName]="textLayerName">
            
            
            <div class="tooltip-menu" [class.visible]="menuVisible">Hallo</div>

            <div class="example-handle" cdkDragHandle>
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
            </div>
            
            <!--
            <button (click)="handleDestroyTextLayer()" type="button" data-layer-id="index"><i class="fa fa-times"></i> DESTROY</button>
            -->
        </div>
    </div>
    <!--
    <div class="text-layer__content" cdkDrag >

        <div tabindex="-1" (focus)="handleFocus()" (blur)="handleFocus()" (dragstart)="handleFocus()" (dragend)="handleFocus()">

            <div class="tooltip-menu" [class.visible]="menuVisible">Hallo</div>
            <button (click)="handleDestroyTextLayer()" type="button">DESTROY</button>
        </div>
    </div>
    -->
`
})
export class TextLayerComponent implements OnInit {

  pdfForm: FormGroup;
  menuVisible = false;
  index: number;
  textLayerName: string;

  @Output()
  requestDestroyTextLayer: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  bums(event) {
    console.log(event);
  }

  ngOnInit() {
    this.textLayerName = 'textLayer' + this.index;
  }

  handleFocus() {

    this.menuVisible = !this.menuVisible;
    // this.toolTipMenu.elementRef.nativeElement.hidden = !this.toolTipMenu.elementRef.nativeElement.hidden;
    // console.log(this.toolTipMenu.elementRef);
  }

  handleDestroyTextLayer() {
    this.requestDestroyTextLayer.emit( this.index );
  }
}
