// Core Imports
import { Input, OnInit } from '@angular/core';

// Vendor Imports
import { fabric } from 'fabric';

(function (fabric) {

  // @ts-ignore
  fabric.Canvas.prototype.historyUndo = [];
  // @ts-ignore
  fabric.Canvas.prototype.historyRedo = [];

  // @ts-ignore
  fabric.Canvas.prototype.historyNext = function (canvas: fabric.Canvas) {

    const _this = canvas;

    // @ts-ignore
    // return JSON.stringify(_this.toDatalessJSON(_this.extraProps));
    // return JSON.stringify(_this.toJSON(_this.extraProps));
    return canvas.toJSON(['lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY', 'lockUniScaling']);
  };

  // @ts-ignore
  fabric.Canvas.prototype.historyInit = function (canvas: fabric.Canvas) {

    const _this = canvas;

    // @ts-ignore
    _this.historyUndo = [];
    // @ts-ignore
    _this.historyRedo = [];
    // @ts-ignore
    _this.historyNextState = this.historyNext(canvas);

    _this.on({
      // 'object:added': this.historySaveAction.bind(null, canvas),
      // 'object:removed': this.historySaveAction.bind(null, canvas),
      'object:modified': this.historySaveAction.bind(null, canvas)
    });
  };

  // @ts-ignore
  fabric.Canvas.prototype.historyDispose = function () {
    this.off({
      'object:added': this.historySaveAction,
      'object:removed': this.historySaveAction,
      'object:modified': this.historySaveAction
    });
  };

  // @ts-ignore
  fabric.Canvas.prototype.historySaveAction = function (canvas) {

    const _this = canvas;

    if (_this.historyProcessing) {
      return;
    }

    const json = _this.historyNextState;
    _this.historyUndo.push(json);
    _this.historyNextState = _this.historyNext(canvas);
  };

  // @ts-ignore
  fabric.Canvas.prototype.undo = function (canvas: fabric.Canvas) {
    // The undo process will render the new states of the objects
    // Therefore, object:added and object:modified events will triggered again
    // To ignore those events, we are setting a flag.

    const _this: fabric.Canvas = canvas;

    // @ts-ignore
    _this.historyProcessing = true;

    // @ts-ignore
    console.log(_this.historyUndo);

    // @ts-ignore
    // let history = _this.historyUndo.pop();
    let history = _this.historyUndo[_this.historyUndo.length - 1];

    // Dummy
    // history = {"version": "3.5.0","objects":[{"type":"rect","originX":"center","originY":"center","left":300,"top":150,"width":150,"height":150,"fill":"#29477F","overlayFill":null,"stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeLineJoin":"miter","strokeMiterLimit":10,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":{"color":"rgba(94, 128, 191, 0.5)","blur":5,"offsetX":10,"offsetY":10},"visible":true,"clipTo":null,"rx":0,"ry":0,"x":0,"y":0},{"type":"circle","originX":"center","originY":"center","left":300,"top":400,"width":200,"height":200,"fill":"rgb(166,111,213)","overlayFill":null,"stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeLineJoin":"miter","strokeMiterLimit":10,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":{"color":"#5b238A","blur":20,"offsetX":-20,"offsetY":-10},"visible":true,"clipTo":null,"radius":100}],"background":""}

    // Modified Original
    console.log(history, 'UnModified Original');

    // history = {"version": "3.5.0","objects":[{"type":"i-text","version":"3.5.0","originX":"center","originY":"center","left":0,"top":0,"width":279.69,"height":40.68,"fill":"rgb(0,0,0)","stroke":null,"strokeWidth":1,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeMiterLimit":4,"scaleX":1,"scaleY":1,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"clipTo":null,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","transformMatrix":null,"skewX":0,"skewY":0,"text":"Gib deinen Text ein","fontSize":36,"fontWeight":"normal","fontFamily":"Calibri","fontStyle":"normal","lineHeight":1.16,"underline":false,"overline":false,"linethrough":false,"textAlign":"left","textBackgroundColor":"","charSpacing":0,"styles":{}}],"background":""};
    console.log(history, 'Modified Original');

    // @ts-ignore
    // console.log(history, 'custom');

    if (history) {
      // Push the current state to the redo history
      // @ts-ignore
      _this.historyRedo.push(_this.historyNext(_this));

      // @ts-ignore
      _this.historyProcessing = false;

      // @ts-ignore
      _this.loadFromJSON(history, _this.renderAll.bind(_this), function(o, object) {
        fabric.log(o, object);
      });
    }
  };

  // @ts-ignore
  fabric.Canvas.prototype.redo = function (canvas) {

    // The undo process will render the new states of the objects
    // Therefore, object:added and object:modified events will triggered again
    // To ignore those events, we are setting a flag.

    this.historyProcessing = true;
    const history = this.historyRedo.pop();

    if (history) {
      // Every redo action is actually a new action to the undo history
      this.historySaveAction(canvas);

      this.loadFromJSON(history, function () {

      }).renderAll();
    }

    this.historyProcessing = false;
  };
})(fabric);
