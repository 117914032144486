// Core Imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultValueService } from '../../services/default-value.service';

// Provider Imports

@Component({
  selector: 'color-palette',
  styleUrls: ['color-palette.component.scss'],
  template: `
    
    <div class="color-palette__wrapper">

      <h1 *ngIf="heading" class="color-palette__title">{{ heading }}</h1>

      <div class="color-palette__inner">
        
        <div *ngFor="let color of availableColors"
             class="color-palette__patch">

          <button [style.background-color]="color"
                  (click)="handleUpdateColorRequest(color)"
                  value="color"
                  [class.is-active]="color === selectedColor"></button>
        </div>
      </div>
    </div>
`
})
export class ColorPaletteComponent implements OnInit {

  availableColors: Array<string>;

  @Input()
  selectedColor: string;

  @Input()
  heading: string;

  @Output()
  updateColorRequest: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dvs: DefaultValueService) {
  }

  ngOnInit() {
    this.availableColors = this.dvs.getDefaultColorCodes();
  }

  handleUpdateColorRequest(color: string) {

    const payload = {
      value: color
    };

    this.updateColorRequest.emit(payload);
  }
}
