// Core Imports

import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { fabric } from 'fabric';

@Injectable()
export class ClipartsService implements OnInit {

  stars = [
    'stars-01',
    'stars-02',
    'stars-03',
    'stars-04',
    'stars-05',
    'stars-06',
    'stars-07',
    'stars-08',
    'stars-09',
    'stars-10',
    'stars-11',
    'stars-12',
    'stars-13',
    'stars-14',
    'stars-15',
  ];

  ngOnInit(): void {

  }

  getAvailableClipArts() {

  }

  getStarClipArts() {

    return this.stars;
  }
}
