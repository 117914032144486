// Core Imports
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Vendor Imports
import { fabric } from 'fabric';

// Provider Imports

@Component({
  selector: '[image-layer]',
  styleUrls: ['image-layer.component.scss'],
  template: `
    <!--
    <div class="text-layer__content" cdkDrag >

        <div tabindex="-1" (focus)="handleFocus()" (blur)="handleFocus()" (dragstart)="handleFocus()" (dragend)="handleFocus()">

            <div class="tooltip-menu" [class.visible]="menuVisible">Hallo</div>
            <button (click)="handleDestroyTextLayer()" type="button">DESTROY</button>
        </div>
    </div>
    -->
`
})
export class ImageLayerComponent implements OnInit {

  @Input()
  canvas: fabric.Canvas;

  pdfForm: FormGroup;

  @Output()
  requestDestroyImageLayer: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit() {

  }

  handleDestroySelf() {
    // this.requestDestroyImageLayer.emit( this.index );
  }
}
