// Core Imports

import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { fabric } from 'fabric';

@Injectable()
export class DefaultValueService implements OnInit {

  defaultFontFamily: BehaviorSubject<string> = new BehaviorSubject<string>('');

  defaultColorCodes = [
    '#000000',
    '#333230',
    '#646263',
    '#999896',
    '#cccacb',
    '#ffffff',
    '#641125',
    '#9a0f50',
    '#ba126a',
    '#e33081',
    '#ec7497',
    '#f5b6c7',
    '#4e1d54',
    '#7f2982',
    '#97408e',
    '#ae5e9b',
    '#bd94b4',
    '#e5bdd7',
    '#052242',
    '#152a6b',
    '#333282',
    '#4e509a',
    '#807bb3',
    '#c5bfdb',
    '#004368',
    '#006ab3',
    '#47a0ca',
    '#2dafcf',
    '#81cde7',
    '#c8e4f0',
    '#235522',
    '#26792b',
    '#6caf21',
    '#99bf0a',
    '#aecf80',
    '#dbe5a8',
    '#66561b',
    '#ad9211',
    '#e7be00',
    '#ffe400',
    '#fadf6a',
    '#fdeeb3',
    '#733816',
    '#ba561a',
    '#ec7406',
    '#f19333',
    '#f6b575',
    '#fcdbb8',
    '#602014',
    '#ae251b',
    '#df2318',
    '#e95c31',
    '#f08e71',
    '#f7c6b7'
  ];

  defaultBackgroundColor = '#f19333';

  defaultTextObjectSettings: fabric.ITextOptions = {
    fontSize: 36,
    fontFamily: 'Arial',
    strokeUniform: true,
    strokeWidth: 0,
    stroke: '#ffffff'
  };

/*  defaultClipArtObjectSettings: fabric.Image = {
    fill: '#ffffff'
  };*/

  defaultCurvedTextSettings = {
    // angle: 180,
    // hasRotatingPoint: false,

    // hasControls: false,
    // lockMovementX: true,
    // lockMovementY: true,

    // flipped: false,
    // rotation: 50,
  };

  defaultObjectBorderSettings: fabric.IObjectOptions = {
    padding: 12,
    cornerSize: 12,
    cornerColor: 'white',
    transparentCorners: false,
    cornerStyle: 'circle',
    centeredRotation: true,
    centeredScaling: true,
    originX: 'center',
    originY: 'center'
  };

  ngOnInit(): void {

  }

  adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  }

  getDefaultStroke() {
    return this.defaultTextObjectSettings.stroke;
  }

  updateDefaultValue(propertyName: string, value: any) {

    this[propertyName].next(value);
  }

  getDefaultValue(propertyName: string) {

    return this[propertyName]['value'];
  }

  getDefaultColorCodes(): Array<string> {
    return this.defaultColorCodes;
  }

  getDefaultTextObjectSettings() {

    return {
      ...this.defaultObjectBorderSettings,
      ...this.defaultTextObjectSettings,
      snapAngle: 15
    };
  }

  getDefaultCurvedTextObjectSettings() {

    return {
      ...this.defaultObjectBorderSettings,
      ...this.defaultTextObjectSettings
    };
  }

  getDefaultBackgroundColor() {
    return this.defaultBackgroundColor;
  }

  getDefaultImageObjectSettings() {
    return {
      ...this.defaultObjectBorderSettings,
      // lockScalingX: true,
      // lockScalingY: true,
      // height: image['originalHeight'],
      // width: image['originalWidth'],
    };
  }

  getDefaultFont() {
    return this.defaultTextObjectSettings.fontFamily;
  }

  setDefaultFont(fontName: string) {
    this.defaultTextObjectSettings.fontFamily = fontName;
  }

  getDefaultObjectBorderSettings() {

    return {
      ...this.defaultObjectBorderSettings
    };
  }
}
