// Core Imports
import { Input, OnInit } from '@angular/core';

// Vendor Imports
import { fabric } from 'fabric';

/*
 export class FabricJsCurvedTextExtension implements OnInit {

 @Input()
 canvas;

 @Input()
 options;

 defaults = {
 top: 0,
 left: 0,
 spacing: 20,
 rotate: 0,
 radius: 50,
 radiusX: null,
 radiusY: null,
 text: 'Curved text',
 align: 'center',
 reverse: false,
 fontSize: 20,
 fontWeight: 'normal',
 fontFamily: 'Arial',
 fill: '#000',
 selectable: true,
 hasControls: false
 };

 ngOnInit(canvas, options) {

 // Options
 this.opts = options || {};

 for (const prop in CurvedText.defaults) {
 if (prop in this.opts) {
 continue;
 }
 this.opts[prop] = CurvedText.defaults[prop];
 }

 this.canvas = canvas;
 this.group = new fabric.Group([], {
 selectable: this.opts.selectable,
 hasControls: this.opts.hasControls
 });
 this.canvas.add(this.group);

 this._forceGroupCoords();
 this.set('text', this.opts.text);
 }


 set(param, value, render) {

 if (typeof param == 'object') {
 for (const i in param) {
 this.set(i, param[i], false);
 }
 } else {
 if (this.opts[param] !== undefined) {
 this.opts[param] = value;
 if (param === 'fontSize' || param === 'fontWeight' || param === 'fill' || param === 'fontFamily') {
 this._setFontStyles();
 }
 if (param === 'selectable') {
 this.group.selectable = value;
 }
 if (param === 'top' || param === 'left') {
 this._forceGroupCoords();
 }
 if (param === 'text') {
 this.setText(value);
 }

 }
 }

 if (render === undefined || render !== false) {
 this._render();
 }
 }

 get(param) {
 if (this.opts[param] !== undefined) {
 return this.opts[param];
 } else {
 return false;
 }
 }

 getParams() {
 return this.opts;
 };


 /!**
 * Remove all letters from canvas
 * @method remove
 *!/
 remove() {

 const size = this.group.size();

 for (let i = size; i >= 0; i--) {
 this.group.remove(this.group.item(i));
 }

 this.canvas.remove(this.group);
 this.canvas.renderAll();
 };

 /!**
 * Used to change the text
 * @method setText
 * @param {string} newText
 *!/
 setText(newText) {

 while (this.group.size() > newText.length) {

 this.group.remove(this.group.item(this.group.size() - 1));
 }

 if (newText.length > 0) {
 for (let i = 0; i < newText.length; i++) {
 if (this.group.item(i) == undefined) {
 const letter = new fabric.Text(newText[i], {
 selectable: false,
 centeredRotation: true,
 originX: 'center',
 originY: 'center'
 });
 this.group.add(letter);
 } else {
 this.group.item(i).text = newText[i];
 }
 }
 }

 this.opts.text = newText;
 this._setFontStyles();
 this._render();
 }

 /!**
 * Update font size and weight
 * @private
 * @method _setFontStyles
 *!/
 _setFontStyles() {

 if (this.group.size() > 0) {

 for (let i = 0; i < this.group.size(); i++) {

 this.group.item(i).set({
 fontSize: this.opts.fontSize,
 lineHeight: 1,
 fontWeight: this.opts.fontWeight,
 fontFamily: this.opts.fontFamily,
 fill: this.opts.fill
 });
 }
 }
 }

 /!**
 * Force update group coords
 * @private
 * @method _forceGroupCoords
 *!/
 _forceGroupCoords() {

 this.group.top = this.opts.top;
 this.group.left = this.opts.left;
 }


 /!**
 * @method on
 *!/
 on(event, callback) {
 this.group.on(event, callback);
 }

 /!**
 * calculate the position and angle of each letter
 * @private
 * @method _render
 *!/
 _render(){
 let _self = this, curAngle = 0, angleRadians = 0, top, left, radiusX, radiusY, rx, ry, items = [], align;

 // Object may have been moved with drag&drop
 this.opts.top = this.group.top;
 this.opts.left = this.group.left;

 if (this.opts.radiusX === null) {
 radiusX = this.opts.radius;
 } else {
 radiusX = this.opts.radiusX;
 }
 if (this.opts.radiusY === null) {
 radiusY = this.opts.radius;
 } else {
 radiusY = this.opts.radiusY;
 }

 radiusX = parseInt(radiusX) - (this.opts.fontSize / 2);
 radiusY = parseInt(radiusY) - (this.opts.fontSize / 2);


 if (this.group.size() > 0) {
 // Text align
 if (this.opts.align == 'center') {
 align = (this.opts.spacing / 2) * (this.group.size() - 1);
 } else if (this.opts.align == 'right') {
 align = (this.opts.spacing) * (this.group.size() - 1);
 } else {
 align = 0;
 }

 this.group.forEachObject(function (a, i) {
 items[i] = a;
 });

 items.forEach(function (a) {
 _self.group.removeWithUpdate(a);
 });

 this.canvas.remove(this.group);


 for (let i = 0; i < items.length; i++) {
 rx = radiusX;
 ry = radiusY;
 // Find coords of each letters (radians : angle*(Math.PI / 180)
 curAngle = (i * parseInt(this.opts.spacing)) + parseInt(this.opts.rotate) - align;
 angleRadians = curAngle * (Math.PI / 180);
 left = Math.sin(angleRadians) * rx;
 top = -Math.cos(angleRadians) * ry;

 if (radiusX != radiusY) {
 let ratioX = rx / (rx + ry),
 ratioY = ry / (rx + ry),
 pct_left = Math.abs(left / rx),
 pct_top = Math.abs(-top / ry),
 ajustedAngle = ((ratioY * 90 * pct_left) + (ratioX * 90 * (1 - pct_top)));
 if (left < 0) {
 ajustedAngle = -ajustedAngle;
 }
 if (-top < 0) {
 ajustedAngle = 180 - ajustedAngle;
 }
 curAngle = ajustedAngle;
 }

 if (this.opts.reverse) {
 curAngle = -curAngle;
 top = -top;
 //console.log( 'top', top, 'angle', curAngle );
 }

 items[i].set({
 'top': top,
 'left': left,
 'angle': curAngle
 });

 if (this.opts.selectable) {
 this.group.addWithUpdate(items[i]);
 } else {
 this.group.add(items[i]);
 }
 }
 }

 // Update group coords
 this.group.set({
 top: this.opts.top,
 left: this.opts.left
 });

 this.group.forEachObject(function (o) {
 o.set('active', false);
 });
 this.canvas.renderAll();
 this.canvas.add(this.group);
 };
 }

 */
/*
 (function(fabric) {

 Object.defineProperty(fabric.Canvas, 'CurvedText', {

 value: fabric.util.createClass(fabric.Text, {

 type: 'curved-text',

 chars: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
 text: '',
 charWidth: 0,
 charHeight: 0,

 initialize: function (element, options) {
 options = options || {};

 this.callSuper('initialize', element, options);

 // this._setWidthHeight();
 },
 /!*
 _setWidthHeight: function () {
 if (!this.getElement()) return;
 if (this.chars.length === 0) return;

 this.charWidth = this.getElement().width / this.chars.length;
 this.charHeight = this.getElement().height;

 this.width = this.charWidth * this.text.length;
 this.height = this.charHeight;
 },

 _renderChar: function (ctx, char, x, y) {
 var charIndex = this.chars.indexOf(char);

 ctx.drawImage(
 this.getElement(),
 charIndex * this.charWidth, 0, this.charWidth, this.charHeight,
 x, y, this.charWidth, this.charHeight);
 },

 _render: function (ctx) {
 this._setWidthHeight();

 var x = -this.width / 2;
 var y = -this.height / 2;

 Array.prototype.forEach.call(this.text, function (char, index) {
 this._renderChar(ctx, char, x + (index * this.charWidth), y);
 }, this);
 }*!/

 })
 });
 })(fabric);*/

(function (fabric) {

  /*
   * CurvedText object for fabric.js
   * @author Arjan Haverkamp (av01d)
   * @date January 2018
   */

  // @ts-ignore
  fabric.CurvedText = fabric.util.createClass(fabric.Object, {
    type: 'curved-text',
    diameter: 420,
    kerning: 3,
    angle: 180,
    text: 'Gib hier deinen Text ein',
    flipped: false,
    fill: '#000000',
    fontFamily: 'Bazooka',
    fontSize: 24, // in px
    fontWeight: '400',
    fontStyle: 'normal', // "normal", "italic" or "oblique".
    cacheProperties: fabric.Object.prototype.cacheProperties.concat('diameter', 'kerning', 'flipped', 'fill', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'text', 'charSpacing'),
    strokeStyle: null,
    stroke: 'yellow',
    strokeWidth: 2,
    charSpacing: 15,
    fontCharacterStyle: 'Caps',

    options: {
      type: 'curved-text',
      diameter: 420,
      kerning: 3,
      angle: 180,
      text: 'Gib hier deinen Text ein',
      flipped: false,
      fill: '#000000',
      fontFamily: 'Bazooka',
      fontSize: 24, // in px
      fontWeight: '400',
      fontStyle: 'normal', // "normal", "italic" or "oblique".
      cacheProperties: fabric.Object.prototype.cacheProperties.concat('diameter', 'kerning', 'flipped', 'fill', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'text', 'charSpacing'),
      strokeStyle: null,
      stroke: 'yellow',
      strokeWidth: 2,
      charSpacing: 15,
    },

    initialize: function (text, opts) {

      const options = opts ? {...this.options, ...opts} : this.options;
      this.text = text;

      this.callSuper('initialize', options);
      this.set('lockUniScaling', true);

      // Draw curved text here initially too, while we need to know the width and height.
      const canvas = this.getCircularText();

      // this._trimCanvas(canvas);

      // this.set('width', canvas.width);
      // this.set('height', canvas.height);

      this.set('width', 300);
      this.set('height', 300);
    },

    _getFontDeclaration: function () {
      return [
        // node-canvas needs "weight style", while browsers need "style weight"
        (fabric.isLikelyNode ? this.fontWeight : this.fontStyle),
        (fabric.isLikelyNode ? this.fontStyle : this.fontWeight),
        this.fontSize + 'px',
        (fabric.isLikelyNode ? ('"' + this.fontFamily + '"') : this.fontFamily)
      ].join(' ');
    },

    _trimCanvas: function (canvas) {

      let w = canvas.width,
        h = canvas.height,
        n;

      const ctx = canvas.getContext('2d'),
        pix = {x: [], y: []},
        imageData = ctx.getImageData(0, 0, w, h),
        fn = function (a, b) {
          return a - b;
        };

      for (let y = 0; y < h; y++) {

        for (let x = 0; x < w; x++) {

          if (imageData.data[((y * w + x) * 4) + 3] > 0) {
            pix.x.push(x);
            pix.y.push(y);
          }
        }
      }

      pix.x.sort(fn);
      pix.y.sort(fn);
      n = pix.x.length - 1;

      w = pix.x[n] - pix.x[0];
      h = pix.y[n] - pix.y[0];
      const cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

      canvas.width = w;
      canvas.height = h;
      ctx.putImageData(cut, 0, 0);
    },

    // Source: http://jsfiddle.net/rbdszxjv/
    getCircularText: function () {
      let text = this.text,
        inwardFacing = true,
        startAngle = 0,
        cw, // character-width
        x; // iterator

      const diameter = this.diameter,
            flipped = this.flipped,
            kerning = this.kerning,
            fill = this.fill,
            charSpacing = this.charSpacing,
            fontCharacterStyle = this.fontCharacterStyle,
            stroke = this.stroke,
            clockwise = -1, // draw clockwise for aligned right. Else Anticlockwise
            strokeWidth = this.strokeWidth,
            canvas = fabric.util.createCanvasElement(),
            ctx = canvas.getContext('2d');

      if (flipped) {
        startAngle = 180;
        inwardFacing = false;
      }

      if (fontCharacterStyle === 'Caps') {
        text = text.toLocaleUpperCase();
      }

      startAngle *= Math.PI / 180; // convert to radians

      // Calc height of text in selected font:
      const d = document.createElement('div');

      d.style.fontFamily = this.fontFamily;
      // !!!!!!!!!!!
      d.style.whiteSpace = 'nowrap';
      d.style.fontSize = this.fontSize + 'px';
      d.style.fontWeight = this.fontWeight;
      d.style.fontStyle = this.fontStyle;

      d.textContent = text;
      document.body.appendChild(d);

      const textHeight = d.offsetHeight;
      document.body.removeChild(d);

      /// Keep on circle path
      canvas.width = canvas.height = diameter;

/*      if (charSpacing) {

        canvas.width += charSpacing;
        canvas.height += charSpacing;
      }*/

      ctx.font = this._getFontDeclaration();

      // Reverse letters for center inward.
      if (inwardFacing) {
        text = text.split('').reverse().join('');
      }

      // Setup letters and positioning
      ctx.translate(diameter / 2, diameter / 2); // Move to center

      startAngle += inwardFacing ? (Math.PI * -1) : Math.PI; // Rotate 180 if outward
      ctx.textBaseline = 'middle'; // Ensure we draw in exact center
      ctx.textAlign = 'center'; // Ensure we draw in exact center

      // rotate 50% of total angle for center alignment
      for (x = 0; x < text.length; x++) {
        cw = ctx.measureText(text[x]).width;
        startAngle += ((cw + (x === text.length - 1 ? 0 : kerning)) / (diameter / 2 - textHeight)) / 2 * -clockwise;
      }

      // Phew... now rotate into final start position
      ctx.rotate(startAngle);

      // Now for the fun bit: draw, rotate, and repeat
      for (x = 0; x < text.length; x++) {

        // Set Stroke
        if (stroke && strokeWidth) {
          ctx.strokeStyle = stroke;
          ctx.lineWidth = strokeWidth * 2;
          // Do not draw outline "farther" than actual letter stroke
          ctx.miterLimit = 2;
          ctx.strokeText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2));
        }

        // Set Fill
        if (fill) {
          ctx.fillStyle = fill;
          ctx.fillText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2));
        }

        cw = ctx.measureText(text[x]).width; // half letter
        // rotate half letter
        ctx.rotate((cw / 2) / (diameter / 2 - textHeight) * clockwise);
        // draw the character at "top" or "bottom"
        // depending on inward or outward facing

        ctx.rotate((cw / 2 + kerning) / (diameter / 2 - textHeight) * clockwise); // rotate half letter
      }

      this.calculateAngle(this.text);
      return canvas;
    },

    calculateAngle: function (text: string) {

      // const letterSpacing = 15;

      const letterSpacing = this.charSpacing;
      let totalWidth = 0;

      const chars = text.split('');

      const len = chars.length;

      for (let i = 0; i < len; i++) {

        const txtField = new fabric.Text(chars[i], {fill: this.fill, fontWeight: 400, fontStyle: 'normal', fontSize: this.fontSize});

        const w = txtField.getScaledWidth();
        totalWidth += txtField.getScaledWidth();
      }

      totalWidth += (len - 1) * letterSpacing;

      const totalCircumference = Math.round((this.horRadius + this.vertRadius) * Math.PI);

      // const totalCircumference = Math.round((totalWidth + 360) * Math.PI);

      const ratio = totalWidth / totalCircumference;

      // 320 + width * PI

      return (ratio * 360) / 2;
    },

    _set: function (key, value) {
      switch (key) {
        case 'scaleX':
          this.fontSize *= value;
          this.diameter *= value;
          this.width *= value;
          this.scaleX = 1;
          if (this.width < 1) {
            this.width = 1;
          }
          break;

        case 'scaleY':
          this.height *= value;
          this.scaleY = 1;
          if (this.height < 1) {
            this.height = 1;
          }
          break;

        case 'charSpacing':

          // alert('curved-text.ts:613');
          break;

        default:
          this.callSuper('_set', key, value);
          break;
      }
    },

    _renderStroke: function (ctx) {
      if (!this.stroke || this.strokeWidth === 0) {
        return;
      }

      if (this.shadow && !this.shadow.affectStroke) {
        this._removeShadow(ctx);
      }

      ctx.save();
      if (this.strokeUniform) {
        ctx.scale(1 / this.scaleX, 1 / this.scaleY);
      }
      this._setLineDash(ctx, this.strokeDashArray, this._renderDashedStroke);
      if (this.stroke.toLive && this.stroke.gradientUnits === 'percentage') {
        // need to transform gradient in a pattern.
        // this is a slow process. If you are hitting this codepath, and the object
        // is not using caching, you should consider switching it on.
        // we need a canvas as big as the current object caching canvas.
        this._applyPatternForTransformedGradient(ctx, this.stroke);
      } else {
        this._applyPatternGradientTransform(ctx, this.stroke);
      }
      ctx.stroke();
      ctx.restore();
    },

    _render: function (ctx) {

      const canvas = this.getCircularText();
      this._trimCanvas(canvas);

      this.set('width', canvas.width);
      this.set('height', canvas.height);

      ctx.drawImage(canvas, -this.width / 2, -this.height / 2, this.width, this.height);

      this.setCoords();
    },

    toObject: function (propertiesToInclude) {
      return this.callSuper('toObject', ['text', 'diameter', 'kerning', 'flipped', 'fill', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'strokeStyle', 'strokeWidth', 'stroke'].concat(propertiesToInclude));
    }
  });

  // @ts-ignore
  fabric.CurvedText.fromObject = function (object, callback, forceAsync) {
    return fabric.Object._fromObject('CurvedText', object, callback, 'curved-text');
  };

})(fabric);
