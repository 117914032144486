// Core Imports
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef, ComponentFactoryResolver, AfterContentInit, ComponentRef, ViewRef, Directive, OnInit
} from '@angular/core';

// Vendor Imports
import { fabric } from 'fabric';
import { IImageOptions } from 'fabric/fabric-impl';
import { LayerOperationsService } from '../../services/layer-operations.service';
import { Subscription } from 'rxjs';
import * as jsPDF from 'jspdf';

// Provider Imports
import { TextLayerComponent } from '../../components/text-layer/text-layer.component';
import { FormGroup, Validators } from '@angular/forms';
import { ImageLayerComponent } from '../../components/image-layer/image-layer.component';

// Services
import { ObjectModificationsService } from '../../services/object-modifications.service';

// Models
import { LayerOperationOption } from '../../interfaces';
import { LayerHostDirective } from '../../directives/layer-host.directive';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ObjectModificationMenuService } from '../../services/object-modification-menu.service';
import { LayerOperationsMenuService } from '../../services/layer-operations-menu.service';
import { DefaultValueService } from '../../services/default-value.service';


@Component({
  selector: 'designer-workspace',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['designer-workspace.component.scss'],
  template: `

      <!--
      <pre style="float: left"> Current Button Color {{ buttonColor | json }}</pre>
      <pre style="float: left"> availableLayerOperations {{ availableLayerOperations | json }}</pre>
  
      <pre style="max-width: 500px; display: block;     float: right;">{{ canvas | json }}</pre>


    <button *ngIf="!!currentLayerIndex" (click)="handleRequestDestroyLayer({type: 'text', index: currentLayerIndex - 1})">Layer mit ID {{ currentLayerIndex - 1 }} löschen</button>
    
    <pre style="float: right">
    {{ this.currentLayerIndex | json }}
    {{ pdfForm?.value | json }}
    </pre>      -->
      
    <object-modifications-menu
            (requestModifyObject)="handleModifyObject($event)"
            [availableObjectModifications]="availableObjectModifications"
            [defaultFont]="dvs.getDefaultFont()"
            [canvas]="canvas"
            [trigger]="availableObjectModifications"></object-modifications-menu>

    <canvas id="canvas"></canvas>

    <ng-template layerHost></ng-template>

    <!--
    <div class="button-container__wrapper" [formGroup]="pdfForm" [style.background-color]="buttonColor">

      <ng-template layerHost></ng-template>

      <div class="button-container">
          
      </div>
    </div>
    -->

    <layer-operations-menu (requestUpdateLayer)="handleUpdateLayer($event)"
                           [availableMenuItems]="availableLayerOperations"
                           [canvas]="canvas"
                           [trigger]="availableLayerOperations"></layer-operations-menu>
    
      <div style="position: absolute; right: 50px; top: 50px">

        <button mat-raised-button color="accent" (click)="downloadPDF()">Download PDF</button>
        <a class="btn btn-clear"
           color="warn"
           mat-raised-button title="Download JSON"
           (click)="downloadCanvasState()"
           [href]="downloadJsonHref"
           download="testbub.json">Download Editor File</a>
          
        <a mat-raised-button color="primary"
           [href]="downloadSVGHref"
           download="test-svg.svg" (click)="generateSVGLink()">Download SVG</a>
      </div>
`
})
export class DesignerWorkspaceComponent implements OnInit, AfterContentInit {

  // @ViewChild('textLayerEntry', {static: false, read: ViewContainerRef}) textLayerEntry: ViewContainerRef;
  @ViewChild(LayerHostDirective, {static: true}) layerHost: LayerHostDirective;

  @Input()
  buttonColor: string;

  @Input()
  pdfForm: FormGroup;

  @Input()
  backgroundImage: any;

  @Input()
  imageDimensions: Object;

  @Output()
  requestDestroyLayer: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  canvas;

  downloadJsonHref: SafeUrl = {};

  downloadSVGHref: any;

  availableLayerOperations: Array<Array<LayerOperationOption>> = [];
  availableObjectModifications: Array<Array<any>> = [];

  currentLayerIndex = 0;

  layerSubscriptions: Array<Subscription> = [];

  constructor(private _viewContainerRef: ViewContainerRef,
              private sanitizer: DomSanitizer,
              private loms: LayerOperationsMenuService,
              private los: LayerOperationsService,
              private oms: ObjectModificationsService,
              private mis: ObjectModificationMenuService,
              private dvs: DefaultValueService,
              private http: HttpClientModule,
              private resolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.availableLayerOperations = this.loms.getDefaultOperations();
    this.availableObjectModifications = this.mis.getGenericObjectModifications();
  }

  ngAfterContentInit(): void {

    // const textLayerFactory = this.resolver.resolveComponentFactory(TextLayerComponent);
    // const component = this.entry.createComponent(textLayerFactory);
  }

  handleModifyObject(e) {

    this.oms.handleObjectOperation(this.canvas, e.operationName, e.payload);
  }

  downloadPDF() {

    // canvas.renderAll();


    const canvas2 = new fabric.Canvas('canvas2');

    canvas2.loadFromJSON(JSON.stringify(this.canvas), function () {

    });

    // See https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa
    // See https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem

    // Initialize JSPDF
    const doc = new jsPDF('l', 'cm', 'a3', 2);

    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = doc.internal.pageSize.getHeight();

    // Converting canvas to Image
    // const imgData = this.canvas.toDataURL({format: 'png', multiplier: 2});
    const imgData = this.canvas.toDataURL({format: 'png', multiplier: 2});

    const svgData = this.canvas.toSVG();
    // const svgXML = (new XMLSerializer()).serializeToString(svgData);

    // console.log(imgData);
    // console.log(svgData);
    console.log('data:image/svg+xml;charset=utf-8;base64,' + btoa(svgData));

    return;
    // console.log(doc.validateStringAsBase64);

    // Add image Canvas to PDF
    doc.addImage(imgData, 'png', 0, 0, pdfWidth, pdfHeight);
    // doc.addImage('data:image/svg+xml;charset=utf-8;base64,' + btoa(svgData), 'PNG' , 0, 0);
    /*const pdfOutput = doc.output();

     // using ArrayBuffer will allow you to put image inside PDF
     const buffer = new ArrayBuffer(pdfOutput.length);
     const array = new Uint8Array(buffer);

     for (let i = 0; i < pdfOutput.length; i++) {
     array[i] = pdfOutput.charCodeAt(i);
     }*/

    // Name of pdf
    const fileName = 'example.pdf';

    // Make file
    doc.save(fileName);

    this.canvas.clone(function (canvas) {

      canvas.setWidth(300);
      canvas.setHeight(300);
    });

/*    const canvasImageRepresentation = this.canvas.toDataURL({format: 'image/png', multiplier: 1.0}); // , 1.0

    const pdf: jsPDF = new jsPDF('l', 'mm', [60, 100]);

    pdf.addImage(canvasImageRepresentation, 'JPEG', 0, 0);

    pdf.save('download.pdf');*/

  }

  downloadCanvasState() {

    // console.log(this.canvas.toJSON());
    // console.log(this.canvas.toJSON(['lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY'])); return;

    const theJSON = JSON.stringify(this.canvas.toJSON());
    const blob = new Blob([theJSON], { type: 'text/json' });
    const url = window.URL.createObjectURL(blob);
    const uri: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(url);

    this.downloadJsonHref = uri;
    console.log(theJSON);
    console.log(blob);
    console.log(url);
    console.log(uri);

    // this.downloadJsonHref = uri;
  }

  generateSVGLink() {

    // @ts-ignore
    fabric.fontPaths['plaster'] = 'http://fonts.gstatic.com/s/plaster/v7/Zh-WKr-VgJ8vp42dO2R9mw.woff2';


    // const svgData = JSON.stringify(this.canvas.toSVG());
    const svgData = this.canvas.toSVG();

    const blob = new Blob([svgData], { type: 'image/svg+xml' });
    const url = window.URL.createObjectURL(blob);
    // const uri: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    const uri: SafeUrl = this.sanitizer.bypassSecurityTrustUrl('data:image/svg+xml;charset=utf-8;base64,' + btoa(svgData));
    console.log(uri);

    this.downloadSVGHref = uri;
  }

  handleUpdateLayer(e: any) {

    this.los.handleLayerOperation(this.canvas, e.operationName, e.payload);
  }

  createTextLayer(): number {

    const layerIndex = this.currentLayerIndex;
    const textLayerFactory = this.resolver.resolveComponentFactory(TextLayerComponent);

    // viewContainerRef.clear();

    const currentComponentRef = this.layerHost.viewContainerRef.createComponent(textLayerFactory, layerIndex );

    currentComponentRef.instance.pdfForm = this.pdfForm;
    currentComponentRef.instance.index = layerIndex;

    // this.componentRefs[layerIndex].instance.pdfForm = this.pdfForm;
    // this.componentRefs[layerIndex].instance.index = layerIndex;
    // const currentView = this._viewContainerRef[layerIndex];
    // this.layerSubscriptions[layerIndex] = ;
    // console.log(this.layerHost.viewContainerRef.length, `${layerIndex}`);

    /*
    currentComponentRef.instance.requestDestroyTextLayer
      .subscribe(
        (index: number) => {

          // console.log(index);
          // this.destroyTextLayer(index);
          alert('Delete Request');
        }
    );
    */

    // console.group();
    // console.log(`layerIndex: ${layerIndex}`);
    // console.log(`this.currentLayerIndex: ${this.currentLayerIndex}`);
    // console.log(this.layerHost.viewContainerRef);
    // console.log(currentComponentRef.componentType);
    // console.log(currentComponentRef.hostView);
    // console.groupEnd();

    this.currentLayerIndex++;

    return layerIndex;

    // console.log(this.componentRefs[layerIndex].instance);
  }

  createClipArtLayer(imageName: string = 'stars-03') {

    const canvas = this.canvas;
    const objectSettings = this.dvs.getDefaultImageObjectSettings();
    const group = [];

/*
    fabric.loadSVGFromURL(`/assets/images/cliparts/stars/${imageName}.svg`, function(objects, settings) {

      console.log(objectSettings);

      const svgGroup = fabric.util.groupSVGElements(objects, objectSettings);

      canvas.add(svgGroup);
      canvas.centerObject(svgGroup);
      canvas.renderAll();

    });
*/

    fabric.loadSVGFromURL(`/assets/images/cliparts/stars/${imageName}.svg`, function() {

        const loadedObjects = new fabric.Group(group);

        loadedObjects.set( {...objectSettings, type: 'clipArt'} );
        canvas.add(loadedObjects);
        canvas.centerObject(loadedObjects);
        canvas.renderAll();
      },
      function(item, object) {
        object.set('id', item.getAttribute('id'));
        group.push(object);
      });
  }

  // @ToDo Index seems to be messed up when deleting layers in any non-reverse order
  handleRequestDestroyLayer({type, index}) {

    this.requestDestroyLayer.emit({type: type, index: index});
  }

  createImageLayer(image: fabric.Image, sanitizedBlob: string, options: IImageOptions) {

    const imageLayerFactory = this.resolver.resolveComponentFactory(ImageLayerComponent);
    const imageComponentRef = this.layerHost.viewContainerRef.createComponent(imageLayerFactory);

    // Pass main canvas element to instance
    imageComponentRef.instance.canvas = this.canvas;

    fabric.Image.fromURL(sanitizedBlob, function(imageObject) {

      imageComponentRef.instance.canvas.add(imageObject);
      imageComponentRef.instance.canvas.centerObject(imageObject);
    }, options);
  }

  // @ToDo Index seems to be messed up when deleting layers in any non-reverse order
  destroyLayer(index: number) {

    this.layerHost.viewContainerRef.detach(index);

    if (!!!this.layerHost.viewContainerRef.length) {

      // Reset if all layers have been removed
      this.currentLayerIndex = 0;
    }
    // this.currentLayerIndex--;
  }

  /*
  old() {
    console.group();
    console.log(`index: ${index}`);
    console.log(`this.currentLayerIndex: ${this.currentLayerIndex}`);
    console.log(this.layerHost.viewContainerRef);
    console.groupEnd();

    // console.log(this.layerHost.viewContainerRef.get(this.currentLayerIndex - 1));
    // this.layerHost.viewContainerRef.detach((this.currentLayerIndex + 1) % this.layerHost.viewContainerRef.length);

    if (this.layerSubscriptions[index]) {

      // this._viewContainerRef.detach(index);
      // this.componentRefs[index].destroy();

      // this.layerSubscriptions[index].unsubscribe();
      // this.layerSubscriptions.splice(index, 1);
      // this.layerHost.viewContainerRef.remove(index);

      // this.componentRefs.splice(index, 1);
    }
  }
   */
}
