// Core Imports
import { Component, Injectable, OnInit } from '@angular/core';

// Vendor Imports
import { fabric } from 'fabric';
import { LayerOperationOption } from '../interfaces';

// Provider Imports

@Injectable()
export class LayerOperationsService implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }

  handleLayerOperation(canvas: fabric.Canvas, operationName: string, payload) {

    // console.log(operationName);
    const activeObj = canvas.getActiveObject();

    // Undo / Redo Actions
    if (operationName === 'undo') {

      // @ts-ignore
      fabric.Canvas.prototype.undo(canvas);
    }

    if (operationName === 'redo') {
      // @ts-ignore
      fabric.Canvas.prototype.redo(canvas);
    }


    // Visibility
    if (operationName === 'toggle_layer_visibility') {

      const actObj = canvas.getActiveObject();
      actObj.get('opacity') === 0 ? actObj.set('opacity', 1) : actObj.set('opacity', 0);
      actObj.get('selectable') === true ? actObj.set('selectable', false) : actObj.set('selectable', true);
    }

    // Set Opacity
    if (operationName === 'set_opacity') {

      activeObj.set('opacity', payload.value);
    }

    // Set Lock
    if (operationName === 'set_lock') {

      const currentState = activeObj.get('selectable');

      activeObj.set('selectable', !currentState);
      activeObj.set('evented', !currentState);
      canvas.discardActiveObject();
      // canvas.remove(canvas.getActiveObject());
    }

    // Delete
    if (operationName === 'delete') {

      canvas.remove(canvas.getActiveObject());
    }

    // Align layer operations
    if (operationName === 'align_horizontally') {
      canvas.getActiveObject().centerH();
    }
    if (operationName === 'align_vertically') {
      canvas.getActiveObject().centerV();
    }

    // Manipulate layer ordering operations
    if (operationName === 'flip_to_front') {
      canvas.getActiveObject().bringForward(true);
    }

    if (operationName === 'flip_to_back') {

      if ( canvas.getObjects().findIndex(item => item === canvas.getActiveObject()) > 2) {

        canvas.getActiveObject().sendBackwards(true);

      } else {

      }
    }

    // Rotate Operations
    if (operationName === 'rotate_45deg_to_right') {

      const rotation = canvas.getActiveObject().get('angle');
      const roundedRotation = Math.round(rotation / 45) * 45;

      canvas.getActiveObject().rotate(roundedRotation + 45);
    }
    if (operationName === 'rotate_45deg_to_left') {

      const rotation = canvas.getActiveObject().get('angle');
      const roundedRotation = Math.round(rotation / 45) * 45;

      canvas.getActiveObject().rotate(roundedRotation - 45);
    }

    // Flip Operations
    if (operationName === 'flip_horizontally') {

      canvas.getActiveObject().set('flipX', canvas.getActiveObject().get('flipX') !== true);
    }
    if (operationName === 'flip_vertically') {

      canvas.getActiveObject().set('flipY', canvas.getActiveObject().get('flipY') !== true);
    }

    if (operationName === 'vertical_align_center') {
      canvas.getActiveObject().centerV();
    }

    canvas.renderAll();
  }
}
