// Core Imports
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { fabric } from 'fabric';

// Provider Imports

@Component({
  selector: 'app-designer',
  styleUrls: ['app.component.scss'],
  template: `
  <div class="app">
    <button-designer></button-designer>
  </div>
`
})
export class AppComponent implements OnInit {

  workspaceDimensions: any;

  width: number;

  height: number;

  availableIcons = [
    'align_horizontally',
    'align_vertically',
    'flip_horizontally',
    'flip_vertically',
    'rotate_left',
    'rotate_right',
    'set_opacity',
    'set_diameter',
    'set_letter_spacing',

    'set_outline',

    'increase_font_size',
    'decrease_font_size',
  ];

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

    this.availableIcons.forEach(item => {

      this.matIconRegistry.addSvgIcon(
        item,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/icons/${item}.svg`)
      );
    });

    this.matIconRegistry.addSvgIcon(
      'curved_text',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/icons/layers/curved_text.svg`)
    );
  }

  ngOnInit(): void {
    // this.populateLocalStorage();
  }

  populateLocalStorage() {

    // Workspace Dimenions
    this.workspaceDimensions = window.getComputedStyle(document.getElementsByTagName('designer-workspace')[0]);
    this.width = parseInt(this.workspaceDimensions.getPropertyValue('width').replace('px', ''));
    this.height = parseInt(this.workspaceDimensions.getPropertyValue('height').replace('px', ''));

    localStorage.setItem('workspaceDimensions', JSON.stringify({
        x: this.width,
        y: this.height
      }
    ));

    const imageName = '_32mm_rund';

    // Cache background image in local storage

    /*    fabric.util.loadImage(`/assets/images/shapes/${imageName}.png`, function (image) {
     localStorage.setItem( 'currentButtonShadeImage', JSON.stringify(image) );
     });*/

    const canvas = new fabric.Canvas('test');

    fabric.Image.fromURL(`/assets/images/shapes/${imageName}.png`, function (image) {

      localStorage.setItem('currentButtonShadeImage', JSON.stringify( image) );
      localStorage.setItem('buttonShadeWidth', image.width.toString() );
      localStorage.setItem('buttonShadeHeight', image.height.toString() );
      localStorage.setItem('currentButtonShadeSrc', `/assets/images/shapes/${imageName}.png` );
      localStorage.setItem('scaleFactor', JSON.stringify(1));

      alert('populated local storage');

      /*      console.log(image, 'button-designer.component.ts: 210');
       console.log(image.toJSON(['filters', 'cacheKey', '_element']), 'button-designer.component.ts: 211');
       console.log(JSON.stringify(image), 'button-designer.component.ts: 212');*/

      const options = {
        // left: (JSON.parse(localStorage.getItem('workspaceDimensions'))['x'] / 2),
        // top: (JSON.parse(localStorage.getItem('workspaceDimensions'))['y'] / 2),
        // originX: 'center',
        // originY: 'center',
        // selectable: false,
        // hoverCursor: 'pointer',
      };

      // fauxBackgroundShapeGroup.center();
      // fauxBackgroundShapeGroup.addWithUpdate(image);
    });
  }
}
