// Core Imports
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { DefaultValueService } from '../../services/default-value.service';
import { MatSlider } from '@angular/material';

// Provider Imports

@Component({
  selector: 'object-modifications-menu',
  styleUrls: ['object-modifications-menu.component.scss'],
  styles: [`
    :host {
        display: block;
        /*overflow: hidden;*/
    }
  `],
  animations: [
    trigger('grow', [
      transition('void <=> *', []),
      transition('* <=> *', [
        style({height: '{{startHeight}}px', opacity: 0, padding: '25px', backgroundColor: '#fff'}),
        animate('.5s ease'),
      ], {params: {startHeight: 0}})
    ])
  ],
  template: `
    <ng-container *ngIf="availableObjectModifications.length > 1">

      <mat-toolbar class="omm__wrapper">

        <mat-toolbar-row>

          <div class="omm__operation-group" *ngFor="let itemGroup of availableObjectModifications;">

            <ng-container *ngFor="let item of itemGroup;">
                
              <ng-container [ngSwitch]="item.operationName">

                <div class="omm__operation-item" [attr.data-action]="item.operationName">

                  <ng-container *ngSwitchCase="'set_font'">
     
                      <mat-form-field>
                          
                        <mat-label>Schriftart</mat-label>
                        <mat-select [value]="defaultFont" (selectionChange)="handleRequestModifyObject({payload: $event, operationName: item.operationName})">
                            
                          <mat-option *ngFor="let fontOption of fontOptions" [value]="fontOption" [style.font-family]="fontOption">{{ fontOption }}</mat-option>
                        </mat-select>
                      </mat-form-field>
  
                  </ng-container>
                    
                    
                  <ng-container *ngSwitchCase="'set_fill'">

                    <button mat-stroked-button (click)="toggleVisibilityState('fillControl')"
                            [matTooltip]="item.tooltipContent"
                            matTooltipPosition="above">

                        <mat-icon *ngIf="!item?.svgIcon">{{item.iconIdentifier}}</mat-icon>
                        <mat-icon *ngIf="item?.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
                    </button>

                    <div class="dropdown-wrapper" data-text-color-wrapper [class.is-visible]="visibilityStates['fillControl']">

                        <!-- fillControl -->
                        <color-palette
                                [heading]="'Textfarbe'"
                                [selectedColor]="valueStates.fillControl"
                                (updateColorRequest)="handleUpdateColor($event, 'set_fill', 'fillControl')"></color-palette>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="'set_outline'">

                    <button mat-stroked-button
                            (click)="toggleVisibilityState('outlineControls')"
                            [matTooltip]="item.tooltipContent"
                            matTooltipPosition="above">

                        <mat-icon *ngIf="!item?.svgIcon">{{item.iconIdentifier}}</mat-icon>
                        <mat-icon *ngIf="item?.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
                    </button>
                      
                    <div class="dropdown-wrapper" [class.is-visible]="visibilityStates['outlineControls']">

                      <div class="slider-wrapper">

                        <mat-slider (change)="handleRequestModifyObject({payload: $event, operationName: item.operationName})"
                                    [value]="valueStates.strokeWidthControl"
                                    #strokeWidthControl
                                    min="0"
                                    step="1"
                                    max="5"
                                    thumbLabel
                                    tickInterval="1"
                                    vertical="true"></mat-slider>
                      </div>

                        <!-- strokeColorControl -->
                      <color-palette
                              [heading]="'Umriss einstellen'"
                              [selectedColor]="valueStates.strokeColorControl"
                              (updateColorRequest)="handleUpdateColor($event, 'set_stroke_color', 'strokeColorControl')"></color-palette>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="'set_text'">
  
                    <mat-form-field>

                      <input (input)="handleRequestModifyObject({payload: $event, operationName: item.operationName})"
                             type="text"
                             matInput
                             [value]="valueStates.textControl">
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngSwitchCase="'set_letter_spacing'">
  
                    <button mat-stroked-button (click)="toggleVisibilityState('letterSpacingControl')">

                      <mat-icon *ngIf="!item?.svgIcon">{{item.iconIdentifier}}</mat-icon>

                      <mat-icon *ngIf="item?.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
                    </button>

                    <mat-slider (input)="handleRequestModifyObject({payload: $event, operationName: item.operationName})"
                                [class.is-visible]="visibilityStates['letterSpacingControl']"
                                [value]="valueStates.letterSpacingControl"
                                min="-150"
                                step="25"
                                max="225"
                                thumbLabel tickInterval="0.2"
                                vertical="true"></mat-slider>

                  </ng-container>

                  <ng-container *ngSwitchCase="'set_radius'">
  
                    <button mat-stroked-button
                            (click)="toggleVisibilityState('radiusControl')"
                            [matTooltip]="item.tooltipContent"
                            matTooltipPosition="above">
  
                      <mat-icon *ngIf="!item?.svgIcon">{{item.iconIdentifier}}</mat-icon>

                      <mat-icon *ngIf="item?.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
                    </button>
  
                    <mat-slider (input)="handleRequestModifyObject({payload: $event, operationName: item.operationName})"
                                [class.is-visible]="visibilityStates['radiusControl']"
                                [value]="valueStates['radiusControl']"
                                min="0"
                                step="20"
                                max="600"
                                thumbLabel
                                tickInterval="20"
                                vertical="true"></mat-slider>

                  </ng-container>

                  <ng-container *ngSwitchCase="'set_kerning'">

                      <button mat-stroked-button
                              (click)="toggleVisibilityState('kerningControl')"
                              [matTooltip]="item.tooltipContent"
                              matTooltipPosition="above">

                          <mat-icon *ngIf="!item?.svgIcon">{{item.iconIdentifier}}</mat-icon>

                          <mat-icon *ngIf="item?.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
                      </button>

                      <mat-slider (input)="handleRequestModifyObject({payload: $event, operationName: item.operationName})"
                                  [class.is-visible]="visibilityStates['kerningControl']"
                                  [value]="valueStates['kerningControl']"
                                  min="-10"
                                  step="1"
                                  max="10"
                                  thumbLabel
                                  tickInterval="1"
                                  vertical="true"></mat-slider>

                  </ng-container>
                    
                  <ng-container *ngSwitchCase="'set_text_align'">
    
                    <button mat-stroked-button (click)="toggleVisibilityState('textAlignControl')"
                            [matTooltip]="item.tooltipContent"
                            matTooltipPosition="above">
  
                      <mat-icon *ngIf="!item?.svgIcon">{{item.iconIdentifier}}</mat-icon>
                      <mat-icon *ngIf="item?.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
                    </button>

                    <mat-button-toggle-group (change)="handleRequestModifyObject({payload: $event, operationName: item.operationName})"
                                             #group="matButtonToggleGroup"
                                             [value]="valueStates.textAlignControl"
                                             vertical="true"
                                             [class.is-visible]="visibilityStates['textAlignControl']">

                        <mat-button-toggle value="left" aria-label="Text align left">
                          <mat-icon>format_align_left</mat-icon>
                        </mat-button-toggle>
                        
                        <mat-button-toggle value="center" aria-label="Text align center">
                          <mat-icon>format_align_center</mat-icon>
                        </mat-button-toggle>
                        
                        <mat-button-toggle value="right" aria-label="Text align right">
                          <mat-icon>format_align_right</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                  </ng-container>

                  <ng-container *ngSwitchDefault>

                    <button [attr.data-value]="item.operationName"
                            [disabled]="item.disabled"
                            mat-stroked-button
                            (click)="handleRequestModifyObject({payload: $event, operationName: item.operationName})"
                            [matTooltip]="item.tooltipContent"
                            matTooltipPosition="above">

                      <mat-icon *ngIf="!item?.svgIcon">{{item.iconIdentifier}}</mat-icon>

                      <mat-icon *ngIf="item?.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
                    </button>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
    </ng-container>
  `
})

export class ObjectModificationsMenuComponent implements OnInit, OnChanges {

  @Input()
  trigger: Array<Array<any>> = [];

  @Input()
  availableObjectModifications = [];

  @Input()
  defaultFont: string;

  @Input()
  canvas: fabric.Canvas;

  @Output()
  requestModifyObject: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('strokeWidthControl', {static: false})
  strokeWidthControl: MatSlider;

  startHeight: number;

  visibilityStates = { };

  valueStates = {
    textControl: '',
    radiusControl: null,
    textAlignControl: 'left',
    strokeColorControl: '#ffffff',
    strokeWidthControl: 0,
    fillControl: '#000000',
    letterSpacingControl: 0,
    kerningControl: 0
  };

  fontOptions = [
    'Broadway',
    'Comic',
    'Arial',
    'Army',
    'Bazooka',
    'Times',
    'Fairly Odd',
    'Arial Narrow',
    'Gill Sans MT Ext Condensed Bold',
    'North Point',
    'Nirvana',
    'Impact',
    'License Plate',
    'URW Typewriter',
    'Bernard MT Condensed',
    'VAG Rounded',
    'Vivaldi',
    'Brush Script BT',
    'Letter Gothic Standard Bold',
    'Copp Gothic Std'
  ];

  constructor(private element: ElementRef) {}

  @HostBinding('@grow') get grow() {
    return {value: this.trigger, params: {startHeight: this.startHeight}};
  }

  ngOnInit(): void {

    this.setInitialVisibilityStates();
  }

  setStartHeight() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  ngOnChanges(changes: SimpleChanges) {

    this.setStartHeight();

    // Menu Opens
    if (changes.trigger && (changes.trigger.currentValue > changes.trigger.previousValue)) {

      // @ts-ignore
      this.valueStates['textControl'] = this.canvas.getActiveObject().get('text');

      // @ts-ignore
      this.valueStates['textAlignControl'] = this.canvas.getActiveObject().get('textAlign');
      // @ts-ignore
      this.valueStates['strokeWidthControl'] = parseInt( this.canvas.getActiveObject().get('strokeWidth') );

      this.valueStates['strokeColorControl'] = this.canvas.getActiveObject().get('stroke');

      // @ts-ignore
      this.valueStates['radiusControl'] = this.canvas.getActiveObject().get('diameter');

      // @ts-ignore
      this.valueStates['kerningControl'] = this.canvas.getActiveObject().get('kerning');
      // @ts-ignore
      // alert(this.canvas.getActiveObject().get('diameter'));
      // angle

      // @ts-ignore
      this.valueStates['fillControl'] = '#' + new fabric.Color( this.canvas.getActiveObject()
        .get('fill') )
        .toHex()
        .toLowerCase(); // Returned Value is upper-cased but should be lower-cased

      // @ts-ignore
      this.valueStates['letterSpacingControl'] = <number>this.canvas.getActiveObject().get('charSpacing');

      // Menu closes
    } else {
      this.setInitialVisibilityStates();

    }
  }

  setInitialVisibilityStates() {

    this.visibilityStates = this.getInitialVisibilityStates();
  }

  getInitialVisibilityStates() {
    return {
      letterSpacingControl: false,
      radiusControl: false,
      kerningControl: false,
      textAlignControl: false,
      outlineControls: false,
      fillControl: false,
    };
  }

  toggleVisibilityState(stateName: string) {

    const updatedStateProperty = {};

    updatedStateProperty[stateName] = !this.visibilityStates[stateName];

    // this.visibilityStates[stateName] = !this.visibilityStates[stateName];

    if ( stateName === 'outlineControls' && this.valueStates['strokeWidthControl'] === 0) {

      this.valueStates['strokeWidthControl'] = 1;
      this.strokeWidthControl.value = 1;

      this.handleRequestModifyObject({payload: {value: this.strokeWidthControl.value}, operationName: 'set_outline'});
    }

    // Unset all other currently open dropdowns
    this.visibilityStates = {...this.getInitialVisibilityStates(), ...updatedStateProperty};


/*    this.setInitialVisibilityStates();
    this.visibilityStates[stateName] = !this.visibilityStates[stateName];*/
  }

  handleRequestModifyObject(e: any) {

    // Button click event
    if (e.hasOwnProperty('target')) {
      this.requestModifyObject.emit(e.target.dataset.value);
      return;
    }

    // Angular input field event, e.g. select dropdown action { value: xyz}
    this.requestModifyObject.emit(e);
  }

  handleUpdateColor(e: any, operationName, valueStateName) {

    this.valueStates[valueStateName] = e.value;

    this.handleRequestModifyObject({payload: e, operationName: operationName});
  }
}
