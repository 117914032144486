// Core Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Vendor Imports
import { ColorPickerModule } from 'ngx-color-picker';
import './extensions/curved-text';
import './extensions/fabric-history';

// Provider Imports
import { ButtonDesignerComponent } from './containers/button-designer/button-designer.component';
import { DesignerMenuComponent } from './components/designer-menu/designer-menu.component';
import { SharedModule as GlobalSharedModule } from '../shared/shared.module';
import { DesignerWorkspaceComponent } from './containers/designer-workspace/designer-workspace.component';
import { TextLayerComponent } from './components/text-layer/text-layer.component';
import { LayerManagementComponent } from './components/layer-management/layer-management.component';
import { ImageLayerComponent } from './components/image-layer/image-layer.component';
import { LayerOperationsMenuComponent } from './components/layer-operations-menu/layer-operations-menu.component';

// Services
import { ImageUploadService } from './services/image-upload.service';
import { LayerOperationsService } from './services/layer-operations.service';
import { ObjectModificationsService } from './services/object-modifications.service';
import { DefaultValueService } from './services/default-value.service';

// Directives
import { LayerHostDirective } from './directives/layer-host.directive';
import { ObjectModificationsMenuComponent } from './components/object-modifications-menu/object-modifications-menu.component';
import { SvgFiltersComponent } from './components/svg-filters/svg-filters.component';
import { MatRippleModule } from '@angular/material';
import { ObjectModificationMenuService } from './services/object-modification-menu.service';
import { LayerOperationsMenuService } from './services/layer-operations-menu.service';
import { ColorPaletteComponent } from './components/color-palette/color-palette.component';
import { ClipartsService } from './services/cliparts.service';
import { DimensionsService } from './services/dimensions.service';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    GlobalSharedModule,
    ColorPickerModule,
    MatRippleModule
  ],
  declarations: [
    // Smart
    ButtonDesignerComponent,
    DesignerWorkspaceComponent,

    // Dumb
    DesignerMenuComponent,
    LayerOperationsMenuComponent,
    ObjectModificationsMenuComponent,
    LayerManagementComponent,
    TextLayerComponent,
    ImageLayerComponent,
    SvgFiltersComponent,
    ColorPaletteComponent,

    // Directives
    LayerHostDirective,
  ],
  exports: [
    ButtonDesignerComponent
  ],
  providers: [
    // Services
    ImageUploadService,
    LayerOperationsService,
    ObjectModificationsService,
    DefaultValueService,
    ObjectModificationMenuService,
    LayerOperationsMenuService,
    ClipartsService,
    DimensionsService

  ],
  entryComponents: [
    TextLayerComponent
  ]
})
export class ButtonDesignerModule {}
