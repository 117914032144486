// Core Imports

import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { fabric } from 'fabric';

@Injectable()
export class DimensionsService implements OnInit {

  buttonWidth = 681;
  buttonHeight = 681;
  workspaceWidth: number;
  workspaceHeight: number;

  ngOnInit(): void {

  }

  updateWorkspaceDimensions() {

    // Workspace Dimenions
    const workspaceDimensions = window.getComputedStyle(document.getElementsByTagName('designer-workspace')[0]);

    this.workspaceWidth = parseInt(workspaceDimensions.getPropertyValue('width').replace('px', ''));
    this.workspaceHeight = parseInt(workspaceDimensions.getPropertyValue('height').replace('px', ''));

    localStorage.setItem('workspaceDimensions', JSON.stringify({
        x: this.workspaceWidth,
        y: this.workspaceHeight
      }
    ));
  }

  getButtonWidth(): number {

    return this.buttonWidth;
  }
  getButtonHeight(): number {

    return this.buttonHeight;
  }

  getWorkspaceWidth(): number {

    return this.workspaceWidth;
  }

  getWorkspaceHeight(): number {

    return this.workspaceHeight;
  }

}
