// Core Imports
import { Injectable } from '@angular/core';
import { LayerOperationOption } from '../interfaces';
import { fabric } from 'fabric';

@Injectable()
export class ObjectModificationMenuService {

  genericObjectModifications = [
    [

    ]
  ];

  availableFilters = [
    [
      {
        operationName: 'set_filter',
        iconIdentifier: 'filter_b_and_w',
        // svgIcon: 'filter_b_and_w',
        tooltipContent: 'Filter anwenden',
        disabled: false,
      }
    ]
  ];

  imageObjectModifications: Array<Array<LayerOperationOption>> = [
    [
      {
        operationName: 'crop_to_16_9',
        iconIdentifier: 'crop_16_9',
        tooltipContent: 'Bild im Verhältnis 16:9 zuschneiden',
        disabled: false,
      }, {
        operationName: 'crop_to_circle',
        iconIdentifier: 'blur_circular',
        tooltipContent: 'Bild kreisförmig zuschneiden',
        disabled: false,
      }, {
        operationName: 'crop_to_square',
        iconIdentifier: 'crop_din',
        tooltipContent: 'Bild quadratisch zuschneiden',
        disabled: false,
      }, {
        operationName: 'crop_reset',
        iconIdentifier: 'crop_rotate',
        tooltipContent: 'Zuschnitt zurücksetzen',
        disabled: false,
      }
    ], [
      {
        operationName: 'set_outline',
        iconIdentifier: 'color_lens',
        tooltipContent: 'Outline einstellen',
        disabled: false
      }
    ],
    ...this.availableFilters
  ];

  curvedTextObjectModifications = [
    [
      {
        operationName: 'set_text',
      }
    ], [
      {
        operationName: 'set_font',
      }
    ], [
      {
        operationName: 'set_letter_spacing',
        svgIcon: 'set_letter_spacing',
        tooltipContent: 'Schriftlaufweite einstellen',
      }, {
        operationName: 'increase_font_size',
        svgIcon: 'increase_font_size',
        tooltipContent: 'increase_font_size',
      }, {
        operationName: 'decrease_font_size',
        svgIcon: 'decrease_font_size',
        tooltipContent: 'decrease_font_size',
      }
    ], [
      {
        operationName: 'set_fill',
        iconIdentifier: 'color_lens',
        tooltipContent: 'Schriftfarbe einstellen',
      }, {
        operationName: 'set_outline',
        svgIcon: 'set_outline',
        tooltipContent: 'Outline einstellen',
      }
    ], [
      {
        operationName: 'set_bold',
        iconIdentifier: 'format_bold',
        tooltipContent: 'Fetten'
      }
    ], [
      {
        operationName: 'set_radius',
        svgIcon: 'set_diameter',
        tooltipContent: 'Radius einstellen',
        disabled: false,
      }, {
        operationName: 'set_kerning',
        svgIcon: 'set_diameter',
        tooltipContent: 'Kerning einstellen',
        disabled: false,
      }
    ]
  ];

  textObjectModifications = [
    [
      {
        operationName: 'set_font',
      }
    ], [
      {
        operationName: 'set_letter_spacing',
        svgIcon: 'set_letter_spacing',
        tooltipContent: 'Schriftlaufweite einstellen',
      }, {
        operationName: 'increase_font_size',
        svgIcon: 'increase_font_size',
        tooltipContent: 'increase_font_size',
      }, {
        operationName: 'decrease_font_size',
        svgIcon: 'decrease_font_size',
        tooltipContent: 'decrease_font_size',
      }
    ], [
      {
        operationName: 'set_fill',
        iconIdentifier: 'color_lens',
        tooltipContent: 'Schriftfarbe einstellen',
      }, {
        operationName: 'set_outline',
        svgIcon: 'set_outline',
        tooltipContent: 'Outline einstellen',
      }
    ], [
      {
        operationName: 'set_text_align',
        iconIdentifier: 'format_align_justify',
        tooltipContent: 'Textausrichtung einstellen'
      }, {
        operationName: 'set_italic',
        iconIdentifier: 'format_italic',
        tooltipContent: 'Schräg stellen'
      }, {
        operationName: 'set_bold',
        iconIdentifier: 'format_bold',
        tooltipContent: 'Fetten'
      }, {
        operationName: 'set_strikethru',
        iconIdentifier: 'strikethrough_s',
        tooltipContent: 'Durchstreichen',
        disabled: false
      }, {
        operationName: 'set_underline',
        iconIdentifier: 'format_underlined',
        tooltipContent: 'Unterstreichen',
        disabled: false
      }
    ]
  ];

  getGenericObjectModifications() {
    return this.genericObjectModifications;
  }

  getCurvedTextObjectModifications() {
    return this.curvedTextObjectModifications;
  }
  getTextObjectModifications() {
    return this.textObjectModifications;
  }

  getImageObjectModifications() {
    return this.imageObjectModifications;
  }

  getAvailableModifications(canvas: fabric.Canvas) {

    const activeObject = canvas.getActiveObject();

    // alert(activeObject.type);

    if (activeObject.type === 'text' || activeObject.type === 'i-text') {

      return [
        ...this.textObjectModifications,
        ...this.genericObjectModifications
      ];
    }

    if (activeObject.type === 'curved-text') {

      return [
        ...this.curvedTextObjectModifications,
        ...this.genericObjectModifications
      ];
    }

    if ( activeObject.type === 'image' ) {

      return [
        ...this.imageObjectModifications,
        ...this.genericObjectModifications
      ];
    }

    if (activeObject.type === 'clipArt') {

      return [
        [
          {
            operationName: 'set_fill',
            iconIdentifier: 'color_lens',
            tooltipContent: 'Schriftfarbe einstellen',
          }, {
            operationName: 'set_outline',
            svgIcon: 'set_outline',
            tooltipContent: 'Outline einstellen',
          }
        ],
        ...this.genericObjectModifications
      ];
    }

    return [
      ...this.genericObjectModifications
    ];
  }
}
