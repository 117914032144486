// Core Imports
import { Component, Injectable, OnInit } from '@angular/core';

// Vendor Imports
import { fabric } from 'fabric';
import { LayerOperationOption } from '../interfaces';

// Provider Imports

@Injectable()
export class LayerOperationsMenuService implements OnInit {

  historyOperations: Array<Array<LayerOperationOption>> = [
    [
      {
        operationName: 'undo',
        iconIdentifier: 'undo',
        tooltipContent: 'Undo',
        disabled: true,
      },
      {
        operationName: 'redo',
        iconIdentifier: 'redo',
        tooltipContent: 'Redo',
        disabled: true,
      }
    ]
  ];

  defaultAlignmentOperations = [
    [
      {
        operationName: 'align_horizontally',
        iconIdentifier: '',
        svgIcon: 'align_horizontally',
        tooltipContent: 'Ebene horizontal mittig ausrichten',
        disabled: false,
      }, {
        operationName: 'align_vertically',
        iconIdentifier: '',
        svgIcon: 'align_vertically',
        tooltipContent: 'Ebene vertikal mittig ausrichten',
        disabled: false,
      }
    ]
  ];

  defaultRotationOperations = [
    [
      {
        operationName: 'rotate_45deg_to_left',
        iconIdentifier: '',
        svgIcon: 'rotate_left',
        tooltipContent: 'Ebene um 45° nach links rotieren',
        disabled: false,
      }, {
        operationName: 'rotate_45deg_to_right',
        iconIdentifier: '',
        svgIcon: 'rotate_right',
        tooltipContent: 'Ebene um 45° nach rechts rotieren',
        disabled: false,
      }
    ]
  ];

  defaultFlipOperations = [
    [
      {
        operationName: 'flip_horizontally',
        iconIdentifier: '',
        svgIcon: 'flip_horizontally',
        tooltipContent: 'Ebene horizontal spiegeln',
        disabled: false,
      }, {
        operationName: 'flip_vertically',
        iconIdentifier: '',
        svgIcon: 'flip_vertically',
        tooltipContent: 'Ebene vertikal spiegeln',
        disabled: false,
      }
    ]
  ];

  defaultOperations: Array<Array<LayerOperationOption>> = [
    [
      {
        operationName: 'toggle_layer_visibility',
        iconIdentifier: 'visibility_off',
        tooltipContent: 'Ebene "sichtbar" schalten',
        disabled: true,
      }, {
        operationName: 'set_opacity',
        svgIcon: 'set_opacity',
        tooltipContent: 'Transparenz einstellen',
        disabled: false,
      }
    ], [
      {
        operationName: 'flip_to_front',
        iconIdentifier: 'flip_to_front',
        tooltipContent: 'Eine Ebene nach vorne versetzen',
        disabled: false,
      }, {
        operationName: 'flip_to_back',
        iconIdentifier: 'flip_to_back',
        tooltipContent: 'Eine Ebene nach hinten versetzen',
        disabled: false,
      }
    ], [
      {
        operationName: 'set_lock',
        iconIdentifier: 'lock_open',
        tooltipContent: 'Ebene sperren',
        disabled: true,
      }, {
        operationName: 'delete',
        iconIdentifier: 'delete',
        tooltipContent: 'Ebene löschen',
        disabled: false,
      }
    ],
    ...this.historyOperations
  ];

  constructor() {
  }

  ngOnInit() {

  }

  getDefaultOperations() {

    return this.historyOperations;
  }

  getAvailableOperations(canvas: fabric.Canvas) {

    const activeObject = canvas.getActiveObject();

    // alert(activeObject.type);

    if (activeObject.type === 'text' || activeObject.type === 'i-text' ) {

      return [
        ...this.defaultAlignmentOperations,
        ...this.defaultRotationOperations,
        ...this.defaultFlipOperations,
        ...this.defaultOperations
      ];
    }

    if ((activeObject.type === 'curved-text') || (activeObject.type === 'curvedText')) {

      return [
        ...this.defaultAlignmentOperations,
        ...this.defaultRotationOperations,
        ...this.defaultFlipOperations,
        ...this.defaultOperations
      ];
    }

    console.log(activeObject);

    if ( (activeObject.type === 'image') || (activeObject.type === 'clipArt') ) {

      return [
        ...this.defaultAlignmentOperations,
        ...this.defaultRotationOperations,
        ...this.defaultFlipOperations,
        ...this.defaultOperations
      ];
    }

    return [
        ...this.defaultOperations
    ];
  }
}
