// Core Imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Provider Imports

@Component({
  selector: 'layer-management',
  styleUrls: ['layer-management.component.scss'],
  template: `
  <div class="layer-management__container">

    <ng-container *ngFor="let layer of layerIndexes">

      <div class="layer-item" hidden>
          
        <p>layer {{ layer + 1 }}</p>

        <div class="button-group">

          <button (click)="handleRequestDeleteLayer({type: 'text', index: layer})" mat-ripple>

            <mat-icon aria-hidden="false" aria-label="Delete this layers">delete</mat-icon>
          </button>

          <button mat-ripple>

            <mat-icon aria-hidden="false" aria-label="Delete this layers">visibility_off</mat-icon>
          </button>

          <button mat-ripple>

            <mat-icon aria-hidden="false" aria-label="Delete this layers">reorder</mat-icon>
          </button>

          <button mat-ripple>

            <mat-icon aria-hidden="false" aria-label="Ebene entsperren">lock_open</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
`
})

export class LayerManagementComponent implements OnInit {

  @Input()
  pdfForm: FormGroup;

  @Output()
  requestDeleteLayer: EventEmitter<any> = new EventEmitter<any>();

  layerIndexes: Array<any> = [];

  constructor() {
  }

  ngOnInit() {

  }

  addLayer(formGroupIndex: number) {

    this.layerIndexes.push( formGroupIndex );
  }

  handleRequestDeleteLayer({type, index}) {

    this.layerIndexes.splice( index, 1 );
    this.requestDeleteLayer.emit({type: type, index: index});
  }
}
