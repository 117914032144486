// Core Imports
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ClipartsService } from '../../services/cliparts.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'designer-menu',
  styleUrls: ['designer-menu.component.scss'],
  template: `
  <div class="menu-sidebar">

    <div class="menu">
        
      <ng-container *ngFor="let item of menuOptions">

        <div class="menu__item">
            
            <ng-container [ngSwitch]="item.actionName">
                
                <ng-container *ngSwitchCase="'set_button_color'">

                    <button [matTooltip]="item.tooltipContent"
                            [disabled]="item.disabled"
                            mat-ripple
                            matTooltipPosition="right"
                            (click)="toggleColorPickerVisibility()">

                        <mat-icon aria-hidden="false" aria-label="Example home icon">{{ item.iconIdentifier }}</mat-icon>
                    </button>

                    <div class="dropdown-wrapper" data-text-color-wrapper [class.is-visible]="colorPickerOpen">

                        <color-palette
                                [heading]="'Button-Farbe'"
                                [selectedColor]="color"
                                (updateColorRequest)="handleRequestUpdateColor($event)"></color-palette>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'create_clipart_layer'">

                    <button [matTooltip]="item.tooltipContent"
                            mat-ripple
                            [disabled]="item.disabled"
                            (click)="toggleVisibilityState('clipArtControl')"
                            matTooltipPosition="right"
                            aria-label="Example icon-button with a menu">

                        <mat-icon aria-hidden="false" aria-label="Example home icon">{{ item.iconIdentifier }}</mat-icon>
                    </button>

                    <div class="dropdown-wrapper" data-clipart-selection [class.is-visible]="visibilityStates.clipArtControl">
                        
                        <div class="clipart-selection" style="display: flex; flex-flow: row wrap">

                            <button *ngFor="let clipartIdentifier of cas.getStarClipArts()"
                                    (click)="handleRequestCreateLayer({type: item.type, payload: {value: clipartIdentifier}})">

                                <img [src]="'/assets/images/cliparts/stars/' + clipartIdentifier + '.svg'" alt="clipart">
                            </button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'create_image_layer'">

                  <label mat-ripple class="button" for="photoInput" [matTooltip]="item.tooltipContent" matTooltipPosition="right">

                      <mat-icon aria-hidden="false" aria-label="">{{ item.iconIdentifier }}</mat-icon>
                      <input type="file" id="photoInput" (change)="handleRequestCreateLayer({type: item.type, event: $event})" style="display: none" accept="image/*">
                  </label>
                </ng-container>
                
                <ng-container *ngSwitchDefault>
                    <button [matTooltip]="item.tooltipContent" mat-ripple [disabled]="item.disabled" matTooltipPosition="right" (click)="handleRequestCreateLayer({type: item.type})">

                        <mat-icon *ngIf="!item?.svgIcon">{{item.iconIdentifier}}</mat-icon>
                        <mat-icon *ngIf="item?.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
                    </button>
                    
                </ng-container>
            </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  `
})
export class DesignerMenuComponent {

  constructor(private cas: ClipartsService, private ds: DomSanitizer) {
  }

  @Output()
  requestCreateLayer: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  requestUpdateColor: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  requestHandleFiles: EventEmitter<any> = new EventEmitter<any>();

  colorPickerOpen = false;

  @Input()
  color;

  menuOptions = [
    {
      actionName: 'create_layer',
      tooltipContent: 'Textebene erstellen',
      iconIdentifier: 'title',
      type: 'text'
    }, {
      actionName: 'create_layer',
      tooltipContent: 'Gekrümmte Textebene erstellen',
      svgIcon: 'curved_text',
      type: 'curvedText'
    }, {
      actionName: 'render_3d',
      tooltipContent: '3D-Ansicht rendern - in Kürze verfügbar',
      iconIdentifier: '3d_rotation',
      type: 'render3d',
      disabled: true
    }, {
      actionName: 'create_clipart_layer',
      tooltipContent: 'ClipArt oder Symbol aus Bibliothek einfügen',
      iconIdentifier: 'tag_faces',
      type: 'clipArt'
    }, {
      actionName: 'create_image_layer',
      tooltipContent: 'Bild hochladen',
      iconIdentifier: 'insert_photo',
      type: 'image'
    }, {
      actionName: 'set_button_color',
      tooltipContent: 'Button-Farbe einstellen',
      iconIdentifier: 'color_lens',
    }
  ];

  visibilityStates = {
    clipArtControl: false
  };

  toggleColorPickerVisibility() {

    this.colorPickerOpen = !this.colorPickerOpen;
  }

  toggleVisibilityState(stateName: string) {

    const updatedStateProperty = {};

    updatedStateProperty[stateName] = !this.visibilityStates[stateName];

    // this.visibilityStates[stateName] = !this.visibilityStates[stateName];

    // Unset all other currently open dropdowns
    this.visibilityStates = {...this.getInitialVisibilityStates(), ...updatedStateProperty};
  }

  getInitialVisibilityStates() {
    return {
      clipArtControl: false
    };
  }

  setInitialVisibilityStates() {
    this.visibilityStates = this.getInitialVisibilityStates();
  }

  handleRequestCreateLayer(requestObject): void {

    console.log(requestObject);
    this.requestCreateLayer.emit(requestObject);
  }

  handleRequestUpdateColor(request: any): void {

    this.requestUpdateColor.emit(request.value);
    // this.toggleColorPickerVisibility();
  }
}
