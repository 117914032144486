// Core Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Vendor Imports
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatButtonToggleModule, MatChipsModule, MatIconModule, MatInputModule, MatMenuModule, MatSelectModule, MatSliderModule, MatSlideToggleModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [
    CommonModule,

    BrowserModule,
    MatSliderModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatToolbarModule,
    MatSelectModule,
    MatInputModule,
    OverlayModule,
    DragDropModule,
    MatSlideToggleModule
  ],
  declarations: [],
  exports: [
    CommonModule,

    BrowserModule,
    MatSliderModule,
    BrowserAnimationsModule,

    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatToolbarModule,
    MatSelectModule,
    MatInputModule,
    DragDropModule,
    OverlayModule,
    MatSlideToggleModule
  ]
})
export class SharedModule { }
