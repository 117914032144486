// Core Imports
import { Injectable, OnInit } from '@angular/core';
import { fabric } from 'fabric';
import { DomSanitizer } from '@angular/platform-browser';

// Provider Imports

@Injectable()
export class ImageUploadService implements OnInit {

  imageBlob;

  constructor(private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

  fetchImageObjectFromUploadedFile(files) {

    // img.src = this.domSanitizer.bypassSecurityTrustUrl( URL.createObjectURL(files[0]) );

    this.imageBlob = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));

    // @ToDo Need this for pdf generation?
    // console.log( URL.createObjectURL( new Blob([ files[0] ]) ) );

    const imgSrcUrl = URL.createObjectURL(files[0]);

    const imageObject: HTMLImageElement = new Image();
    // imageObject.src = imgSrcUrl;
    imageObject.src = this.imageBlob.changingThisBreaksApplicationSecurity;

    const returnObject = {
      originalWidth: 0,
      originalHeight: 0,
      HTMLImageObject: null,
      sanitizedURL: this.imageBlob.changingThisBreaksApplicationSecurity
    };

    imageObject.onload = function () {

      const width = imageObject.width;
      const height = imageObject.height;

      // alert(`width: ${width} | height: ${height} `);

      imageObject.width = width;
      imageObject.height = height;

      returnObject.originalWidth = width;
      returnObject.originalHeight = height;

      window.URL.revokeObjectURL(imageObject.src);

      localStorage.setItem('imageDimensions',
        JSON.stringify({
            originalHeight: imageObject.height, originalWidth: imageObject.width
          }
        )
      );
    };

    // @ToDo Move and implement
    // this.pdfFormBase.createFormGroup( [ this.createFormControl(`imageLayer`, this.createFormControl(this.imageBlob.changingThisBreaksApplicationSecurity, [Validators.required])) ] );

    returnObject.HTMLImageObject = imageObject;

    const dimensions = JSON.parse(localStorage.getItem('imageDimensions'));

    return returnObject;

    /*
     this.canvas.setBackgroundImage(new fabric.Image(imageObject), this.canvas.renderAll.bind(this.canvas), {
     height: dimensions['originalHeight'],
     width: dimensions['originalWidth'],
     // Needed to position backgroundImage at 0/0
     originX: 'left',
     originY: 'top'
     });*/
    /*
     this.canvas.setOverlayImage(new fabric.Image(imageObject), this.canvas.renderAll.bind(this.canvas), {
     // Needed to position overlayImage at 0/0
     originX: 'left',
     originY: 'top',
     height: dimensions['originalHeight'] * 0.25,
     width: dimensions['originalWidth'] * 0.25,
     });*/

    // console.log(imageObject, 'imageobject');

    fabric.Image.fromURL(imgSrcUrl, function (myImg) {

      const img1 = myImg.set({left: 0, top: 0, height: dimensions['originalHeight'], width: dimensions['originalWidth']});

      localStorage.setItem('imageSource',
        JSON.stringify(img1)
      );
    });

/*    this.canvas.add(JSON.parse(localStorage.getItem('imageSource')));

    this.imageDimensions = dimensions;

    this.pdfFormBase.addControl(
      'imageLayer',
      this.createFormControl({url: this.imageBlob.changingThisBreaksApplicationSecurity, originalHeight: dimensions['originalHeight'], originalWidth: dimensions['originalWidth']})
    );*/
  }

  // GET THE IMAGE WIDTH AND HEIGHT USING fileReader() API.
  readImageFile(file) {

    const reader = new FileReader(); // CREATE AN NEW INSTANCE.

    return reader.readAsDataURL(file);
  }
}
