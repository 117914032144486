// Core Imports
import { Component, OnInit } from '@angular/core';

// Provider Imports

@Component({
  selector: 'svg-filters',
  templateUrl: './instagram-filters.svg'
})

export class SvgFiltersComponent {
  constructor() {
  }
}
