// Core Imports
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';

// Provider Imports
import { ButtonDesignerModule } from './button-designer/button-designer.module';
import { SharedModule as GlobalSharedModule } from './shared/shared.module';

@NgModule({
  imports: [
    BrowserModule,
    GlobalSharedModule,
    ButtonDesignerModule
  ],
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent
  ]
})
export class AppModule {}
