// Core Imports
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, ValidatorFn, FormControl } from '@angular/forms';

// Vendor Imports
import { fabric } from 'fabric';
import { ICanvasOptions, ITextOptions } from 'fabric/fabric-impl';
import { fromEvent, Subscription, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

// Provider Imports
import { DesignerWorkspaceComponent } from '../designer-workspace/designer-workspace.component';
import { LayerManagementComponent } from '../../components/layer-management/layer-management.component';
import { ImageUploadService } from '../../services/image-upload.service';
import { LayerOperationsService } from '../../services/layer-operations.service';
import { ObjectModificationsService } from '../../services/object-modifications.service';
import { DefaultValueService } from '../../services/default-value.service';
import { ObjectModificationMenuService } from '../../services/object-modification-menu.service';
import { LayerOperationsMenuService } from '../../services/layer-operations-menu.service';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { DimensionsService } from '../../services/dimensions.service';

@Component({
  selector: 'button-designer',
  styleUrls: ['button-designer.component.scss'],
  template: `
    
    <svg-filters></svg-filters>
    
    <div class="button-designer">

      <designer-menu
              [color]="bgColor"
              (requestUpdateColor)="handleUpdateColor($event)"
              (requestCreateLayer)="handleCreateLayer($event)">
      </designer-menu>

      <designer-workspace
              [canvas]="canvas"
              [buttonColor]="bgColor"
              [imageDimensions]="imageDimensions"
              [backgroundImage]="imageBlob"
              (requestUpdateButtonColor)="handleUpdateColor($event)"
              (requestDestroyLayer)="handleDestroyLayer($event)"
              [pdfForm]="pdfFormBase">
      </designer-workspace>

      <layer-management [pdfForm]="pdfFormBase" (requestDeleteLayer)="handleDestroyLayer($event)"></layer-management>
    </div>
`
})
export class ButtonDesignerComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private fb: FormBuilder,
              private uploadService: ImageUploadService,
              private los: LayerOperationsService,
              private loms: LayerOperationsMenuService,
              private oms: ObjectModificationsService,
              private mis: ObjectModificationMenuService,
              private dvs: DefaultValueService,
              private dss: DimensionsService) {
  }

  bgColor: string; // initially coral

  initialButtonDefinition = {
    type: 'circle',
    radius: 250,
    saveZoneOffset: 50
  };

  initialCanvasOptions: ICanvasOptions = {
    containerClass: 'canvas-wrapper',
    controlsAboveOverlay: true,
    defaultCursor: 'default',
    // viewportTransform: [1],
    // backgroundColor: 'rgba(212,211,218,0.5)',
    backgroundColor: 'transparent',
    selection: false, // Disable group selection
    // Prevent index change when, for instance, selecting a text element underneath an image
    preserveObjectStacking: true
  };

  keyupEvent: Observable<any>;
  keyupEventSubscription: Subscription;

  workspaceDimensions: any;
  width: any;
  height: any;

  canvas: fabric.Canvas;

  imageBlob: string | any = null;
  imageDimensions: Object;

  curvedText;

  pdfFormBase: FormGroup = this.fb.group({});

  index = 0;

  @ViewChild(DesignerWorkspaceComponent, {static: false, read: DesignerWorkspaceComponent})
  workspace: DesignerWorkspaceComponent;

  @ViewChild(LayerManagementComponent, {static: false, read: LayerManagementComponent})
  layerManagement: LayerManagementComponent;

  ngOnInit() {

    this.populateLocalStorage();

    this.dss.updateWorkspaceDimensions();

    // Workspace Dimenions
/*    this.workspaceDimensions = window.getComputedStyle(document.getElementsByTagName('designer-workspace')[0]);
    this.width = parseInt(this.workspaceDimensions.getPropertyValue('width').replace('px', ''));
    this.height = parseInt(this.workspaceDimensions.getPropertyValue('height').replace('px', ''));

    localStorage.setItem('workspaceDimensions', JSON.stringify({
        x: this.width,
        y: this.height
      }
    ));*/


    // fabric.util.addListener()
    this.bgColor = this.dvs.getDefaultBackgroundColor();

    this.canvas = new fabric.Canvas('canvas', this.initialCanvasOptions);

    this.canvas.setDimensions({
      width: this.dss.getWorkspaceWidth(),
      height: this.dss.getWorkspaceHeight()
    });

    // Add keyup event for hotkey and deletion via DEL
    this.canvas.on('custom:event', function (e) {

      // console.log(e['event']);
      // console.log(e['canvas']);
      // console.log(e['_this']);

      const canvas: fabric.Canvas = e['canvas'];

      canvas.remove(canvas.getActiveObject());
    });

    // Add event listener for a selection event

    this.canvas.on('selection:created', this.handleSelectionEvent.bind(null, {
      type: 'selection:created',
      canvas: this.canvas,
      initialButtonDefinition: this.initialButtonDefinition,
      width: this.dss.getWorkspaceWidth(),
      height: this.dss.getWorkspaceHeight(),
      saveZoneOffset: this.initialButtonDefinition.saveZoneOffset,
      _this: this
    }));

    this.canvas.on('selection:updated', this.handleSelectionEvent.bind(null, {
      type: 'selection:updated',
      canvas: this.canvas,
      initialButtonDefinition: this.initialButtonDefinition,
      width: this.dss.getWorkspaceWidth(),
      height: this.dss.getWorkspaceHeight(),
      saveZoneOffset: this.initialButtonDefinition.saveZoneOffset,
      _this: this
    }));

    // Add event listener for a deselection event

    this.canvas.on('selection:cleared', this.handleSelectionEvent.bind(null, {
      type: 'selection:cleared',
      canvas: this.canvas,
      initialButtonDefinition: this.initialButtonDefinition,
      width: this.dss.getWorkspaceWidth(),
      height: this.dss.getWorkspaceHeight(),
      saveZoneOffset: 0,
      _this: this
    }));

    // this.canvas.historyInit();

    this.setCanvasCrop(this.canvas, this.initialButtonDefinition, this.dss.getWorkspaceWidth(), this.dss.getWorkspaceHeight(), 0);

    // @ts-ignore
    fabric.Canvas.prototype.historyInit(this.canvas);

    // Create dummy text layer
    // this.handleCreateLayer({type: 'text'});
  }

  ngAfterViewInit(): void {

    this.keyupEvent = fromEvent(window, 'keyup').pipe(
      filter((e: KeyboardEvent) => e.code === 'Delete'),
      // debounceTime(200),
      distinctUntilChanged()
    );

    this.keyupEventSubscription = this.keyupEvent.subscribe(v => this.canvas.trigger('custom:event', {event: v, canvas: this.canvas, _this: this}));

    this.setFauxBackgroundShape();
    this.setRealBackgroundShape();
  }

  populateLocalStorage() {

    const imageName = '_32mm_rund';

    fabric.Image.fromURL(`/assets/images/shapes/${imageName}.png`, function (image) {

      function getDataUri(url, callback) {

        const imgObj = new Image();

        imgObj.onload = function (e) {
          const canvas = document.createElement('canvas');

          canvas.width = imgObj.naturalWidth; // or 'width' if you want a special/scaled size
          canvas.height = imgObj.naturalHeight; // or 'height' if you want a special/scaled size

          canvas.getContext('2d').drawImage(imgObj, 0, 0);

          // Get raw image data
          // callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

          // ... or get as Data URI
          callback(canvas.toDataURL('image/png'));
        };

        imgObj.src = url;
      }

      // Cache background image in local storage
      getDataUri(image.getSrc(), function (dataUri) {

        localStorage.setItem('currentButtonShadeUri', JSON.stringify( dataUri) );
      });

      localStorage.setItem('currentButtonShadeImage', JSON.stringify( image) );
      localStorage.setItem('buttonShadeWidth', image.width.toString() );
      localStorage.setItem('buttonShadeHeight', image.height.toString() );
      localStorage.setItem('currentButtonShadeSrc', `/assets/images/shapes/${imageName}.png` );
      localStorage.setItem('scaleFactor', JSON.stringify(1));

      // alert('populated local storage');

/*      console.log(image, 'button-designer.component.ts: 210');
      console.log(image.toJSON(['filters', 'cacheKey', '_element']), 'button-designer.component.ts: 211');
      console.log(JSON.stringify(image), 'button-designer.component.ts: 212');*/

      const options = {
        // left: (JSON.parse(localStorage.getItem('workspaceDimensions'))['x'] / 2),
        // top: (JSON.parse(localStorage.getItem('workspaceDimensions'))['y'] / 2),
        // originX: 'center',
        // originY: 'center',
        // selectable: false,
        // hoverCursor: 'pointer',
      };

      // fauxBackgroundShapeGroup.center();
      // fauxBackgroundShapeGroup.addWithUpdate(image);
    });
  }

  findByLayerName(name: string, canvas = null) {

    if (canvas) {

      return canvas.getObjects().findIndex((layer) => layer.name === name);
    }

    return this.canvas.getObjects().findIndex((layer) => layer.name === name);
  }

  setFauxBackgroundShape() {

    if (this.initialButtonDefinition.type === 'circle') {

      this.loadOverlayImage();

      const fauxBackgroundShapeGroup = new fabric.Group([], {
        name: 'fauxBackgroundShapeGroup',
        selectable: false,
        hoverCursor: 'default'
      });

      const buttonBackgroundColor = new fabric.Circle({
        fill: this.bgColor,
        // radius: JSON.parse(localStorage.getItem('currentButtonShadeImage')).width / 2,
        radius: this.dss.getButtonWidth() / 2,
        strokeDashArray: [0, 0],
        stroke: 'black',
        strokeWidth: 0,
        selectable: false,
        hoverCursor: 'default',
        name: 'backgroundShape',
        originX: 'center',
        originY: 'center',
        // shadow: '4px 4px 20px rgba(1, 1, 1, 0.3) inset',
        left: this.dss.getWorkspaceWidth() / 2,
        top: this.dss.getWorkspaceHeight() / 2
      });

      fauxBackgroundShapeGroup.addWithUpdate(buttonBackgroundColor);
      // fauxBackgroundShapeGroup.bringToFront();
      // fauxBackgroundShapeGroup.evented = false;

      this.canvas.add(fauxBackgroundShapeGroup);
      this.canvas.centerObject(fauxBackgroundShapeGroup);
      // this.canvas.sendToBack(fauxBackgroundShapeGroup);
    }
  }

  loadOverlayImage() {

    const canvas = this.canvas;
    // const imageUrl = JSON.parse( localStorage.getItem('currentButtonShadeSrc') );

    const imageName = '_32mm_rund';
    const imageUrl =  `/assets/images/shapes/${imageName}.png`;

    fabric.Image.fromURL(imageUrl, function (image) {

      canvas.setOverlayImage(image, function () { } /*, options*/);
      canvas.centerObject(image);
      canvas.renderAll();
    });

    // console.log(image, 'button-designer.component.ts:283 - loadOverlayImage');
  }

  hideFauxBackgroundShape() {

    const fauxBackgroundGroup: fabric.StaticCanvas = this.canvas.item(this.findByLayerName('fauxBackgroundShapeGroup'));

    this.canvas.overlayImage = null;

    fauxBackgroundGroup.getObjects().forEach(object => {

      // fauxBackgroundGroup.remove(object);
      object.animate('opacity', 0, {
        duration: 200,
        onChange: this.canvas.renderAll.bind(this.canvas),
      });
    });

    // this.canvas.renderAll();
  }

  showFauxBackgroundShape() {

    const fauxBackgroundGroup: fabric.StaticCanvas = this.canvas.item(this.findByLayerName('fauxBackgroundShapeGroup'));

    this.loadOverlayImage();

    fauxBackgroundGroup.getObjects().forEach(object => {

      // fauxBackgroundGroup.remove(object);
      object.animate('opacity', 1, {
        duration: 100,
        onChange: this.canvas.renderAll.bind(this.canvas),
      });
    });

    // this.canvas.renderAll();
  }

  setRealBackgroundShape() {

    // Set the outer rim of the backgorund shape i.e. the buttons real dimensions
    const saveZoneBackgroundColor = new fabric.Circle({
      fill: '#c5c5c5',
      radius: this.initialButtonDefinition.radius + this.initialButtonDefinition.saveZoneOffset,
      strokeDashArray: [0, 0],
      stroke: 'rgba(161,161,161,0.8)',
      strokeWidth: 3,
      selectable: false,
      name: 'saveZoneBackgroundShape',
      left: this.dss.getWorkspaceWidth() / 2,
      top: this.dss.getWorkspaceHeight() / 2,
      originX: 'center',
      originY: 'center',
      opacity: 0
    });

    // Set the inner rim i.e. the actual cropping zone of the buttons
    const saveZoneShape = new fabric.Circle({
      fill: 'transparent',
      radius: this.initialButtonDefinition.radius,
      strokeDashArray: [15, 15],
      stroke: 'rgba(161,161,161,0.8)',
      strokeWidth: 3,
      selectable: false,
      name: 'saveZoneShape',
      left: this.dss.getWorkspaceWidth() / 2,
      top: this.dss.getWorkspaceHeight() / 2,
      originX: 'center',
      originY: 'center',
      opacity: 0
    });

    const realDimensionsShapeGroup = new fabric.Group([saveZoneBackgroundColor, saveZoneShape], {
      name: 'realBackgroundShapeGroup',
      selectable: false
    });

    this.canvas.add(realDimensionsShapeGroup);
    this.canvas.centerObject(realDimensionsShapeGroup);
    // this.canvas.sendToBack(realDimensionsShapeGroup);
  }

  hideRealBackgroundShape() {

    const realBackgroundGroup: fabric.StaticCanvas = this.canvas.item(this.findByLayerName('realBackgroundShapeGroup'));

    realBackgroundGroup.getObjects().forEach(object => {

      // realBackgroundGroup.remove(object);

      object.animate('opacity', 0, {
        duration: 200,
        onChange: this.canvas.renderAll.bind(this.canvas),
      });
    });

    // this.canvas.remove(realBackgroundGroup);
  }

  showRealBackgroundShape() {

    const realBackgroundGroup: fabric.StaticCanvas = this.canvas.item(this.findByLayerName('realBackgroundShapeGroup'));

    realBackgroundGroup.getObjects().forEach(object => {

      // realBackgroundGroup.remove(object);

      object.animate('opacity', 1, {
        duration: 200,
        onChange: this.canvas.renderAll.bind(this.canvas),
      });
    });

    // this.canvas.remove(realBackgroundGroup);
  }

  setCanvasCrop(canvas: fabric.Canvas, buttonDefinition, width: number, height: number, offset: number) {

/*    canvas.animate('clipTo', buttonDefinition.radius, {
      duration: 700,
      onChange: function (ctx: CanvasRenderingContext2D) {

        ctx.arc(width / 2, height / 2, buttonDefinition.radius + (offset ? offset : 0), 0, Math.PI * 2, true);
      }
    });*/

    fabric.util.animate({
      byValue: offset,
      duration: 200,
      startValue: offset !== 0 ? buttonDefinition.radius : (buttonDefinition.radius + offset),
      endValue: offset !== 0 ? (buttonDefinition.radius + offset) : buttonDefinition.radius,
      onChange: function (radius) {

        // @ts-ignore
        canvas.set('clipTo', function (ctx: CanvasRenderingContext2D) {

          ctx.arc(width / 2, height / 2, radius, 0, Math.PI * 2, true);
        });
        canvas.renderAll();
      }
    });
/*
    canvas.clipTo = function (ctx: CanvasRenderingContext2D) {

      ctx.arc(width / 2, height / 2, buttonDefinition.radius + (offset ? offset : 0), 0, Math.PI * 2, true);
    };*/
  }

  handleUpdateColor(colorCode: string) {

    const fauxBackgroundShape = this.canvas.item(this.findByLayerName('fauxBackgroundShapeGroup'));
    const colorLayer = fauxBackgroundShape.item( this.findByLayerName('backgroundShape', fauxBackgroundShape) );

    // @ts-ignore
    colorLayer.set('fill', colorCode);
    this.bgColor = colorCode;

    this.canvas.renderAll();
  }

  handleCreateLayer(request) {

    const type = request.type;

    if (type === 'text') {

      this.index = this.workspace.createTextLayer();
      // this.pdfFormBase.addControl(`textLayer${this.index}`, this.createFormControl(`Ihr Text ${this.index}`, [Validators.required]));

      const text: fabric.IText = new fabric.IText('Gib deinen Text ein :-)', this.dvs.getDefaultTextObjectSettings());

      this.canvas.add(text);
      this.canvas.centerObject(text);

      this.pdfFormBase.addControl(`textLayer${this.index}`, this.createFormControl(text, [Validators.required]));
      this.layerManagement.addLayer(this.index);
    }

    if ( type === 'clipArt') {

      const clipArt = request.payload.value;

      this.workspace.createClipArtLayer(clipArt);
    }

    if (type === 'image') {

      const eventTarget = request.event.target;
      const image = this.uploadService.fetchImageObjectFromUploadedFile(eventTarget.files);

      const fabricImageObject = new fabric.Image(image.HTMLImageObject);

      this.workspace.createImageLayer(fabricImageObject, image.sanitizedURL, this.dvs.getDefaultImageObjectSettings());

      this.pdfFormBase.addControl(`imageLayer${this.index}`, this.createFormControl(image.sanitizedURL, [Validators.required]));
    }

    if (type === 'curvedText') {

      // @ts-ignore
      const curvedText = new fabric.CurvedText('Gib deinen Text ein', {
        diameter: 420,
        left: this.dss.getWorkspaceWidth() / 2,
        top: (this.dss.getWorkspaceHeight() / 2) - ((this.initialButtonDefinition.radius - this.initialButtonDefinition.saveZoneOffset) * 0.5),
        ...this.dvs.getDefaultCurvedTextObjectSettings()
      });

      this.canvas.add(curvedText);
      this.layerManagement.addLayer(this.index);
      // this.canvas.centerObject(curvedText);
    }
  }

  // Toggles image of the actual button for the user
  handleSelectionEvent({type, canvas, initialButtonDefinition, width, height, saveZoneOffset, _this}:
                         { type: string, canvas: any, initialButtonDefinition: any, width: number, height: number, saveZoneOffset: number, _this: ButtonDesignerComponent }) {

    if (type === 'selection:created') {

      _this.hideFauxBackgroundShape();
      _this.showRealBackgroundShape();
      _this.workspace.availableLayerOperations = _this.loms.getAvailableOperations(_this.canvas);
      _this.workspace.availableObjectModifications = _this.mis.getAvailableModifications(_this.canvas);

      _this.setCanvasCrop(canvas, initialButtonDefinition, width, height, saveZoneOffset);
    }

    if (type === 'selection:cleared') {

      _this.hideRealBackgroundShape();
      _this.showFauxBackgroundShape();
      _this.workspace.availableLayerOperations = _this.loms.getDefaultOperations();
      _this.workspace.availableObjectModifications = _this.mis.getGenericObjectModifications();

      _this.setCanvasCrop(canvas, initialButtonDefinition, width, height, saveZoneOffset);
    }

    if (type === 'selection:updated') {

      _this.workspace.availableLayerOperations = _this.loms.getAvailableOperations(_this.canvas);
      _this.workspace.availableObjectModifications = _this.mis.getAvailableModifications(_this.canvas);
    }
  }

  handleDestroyLayer({type, index}) {

    if (type === 'text') {

      this.workspace.destroyLayer(index);
      this.pdfFormBase.removeControl(`textLayer${index}`);
    }
  }

  private createFormGroup(formControls: Array<FormControl>) {

    return this.fb.array(formControls);
  }

  private createFormControl(defaultValue, validators: Array<ValidatorFn> = [Validators.required]) {

    // return this.fb.control('', [Validators.required]);
    return this.fb.control(defaultValue, validators);
  }

  ngOnDestroy(): void {

    if (this.keyupEventSubscription) {

      this.keyupEventSubscription.unsubscribe();
    }
  }
}
