// Core Imports
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

// Provider Imports
import { LayerOperationsService } from '../../services/layer-operations.service';
import { LayerOperationOption } from '../../interfaces';

@Component({
  selector: 'layer-operations-menu',
  styleUrls: ['layer-operations-menu.component.scss'],
  styles: [`
    :host {
      display: block;
      /*overflow: hidden;*/
    }
  `],
  animations: [
    trigger('grow', [
      transition('void <=> *', []),
      transition('* <=> *', [
        style({height: '{{startHeight}}px', opacity: 0}),
        animate('.5s ease'),
      ], {params: {startHeight: 0}})
    ])
  ],
  template: `
    
    <ng-container *ngIf="availableMenuItems.length > 0">

      <mat-toolbar class="lom__wrapper">

        <mat-toolbar-row>
            
          <div class="lom__operation-group" *ngFor="let itemGroup of availableMenuItems;">

            <ng-container *ngFor="let item of itemGroup;">
              
              <ng-container [ngSwitch]="item.operationName">

                <div class="lom__operation-item" [attr.data-action]="item.operationName">

                  <ng-container *ngSwitchCase="'set_opacity'">
  
                    <button mat-stroked-button
                            (click)="toggleVisibilityState('opacityControl')"
                            [matTooltip]="item.tooltipContent"
                            matTooltipPosition="above">
  
                      <mat-icon *ngIf="!item?.svgIcon">{{item.iconIdentifier}}</mat-icon>
                      <mat-icon *ngIf="item?.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
                    </button>
  
                    <div class="dropdown-wrapper"
                         data-single-slider
                         [class.is-visible]="visibilityStates['opacityControl']">
                        
                      <mat-slider (input)="handleRequestUpdateLayer({payload: $event, operationName: item.operationName})"
                                  [class.is-visible]="visibilityStates['opacityControl']"
                                  [value]="valueStates.opacityControl"
                                  min="0"
                                  step="0.1"
                                  max="1"
                                  thumbLabel
                                  tickInterval="0.1"
                                  vertical="true"></mat-slider>
                    </div>
                  </ng-container>
                  
                  <ng-container *ngSwitchDefault>
  
                    <ng-container
                            [ngTemplateOutlet]="!item.isToggle ? button : toggle"
                            [ngTemplateOutletContext]="{item: item, altToggle: altToggle}">
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
              
              <ng-template #button>
                
                <button class="lom__operation-item" [attr.data-value]="item.operationName" [disabled]="item.disabled"
                        mat-stroked-button
                        (click)="handleRequestUpdateLayer({payload: $event, operationName: item.operationName})"
                        [matTooltip]="item.tooltipContent"
                        matTooltipPosition="above">

                  <mat-icon *ngIf="!item?.svgIcon">{{item.iconIdentifier}}</mat-icon>

                  <mat-icon *ngIf="item?.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
                </button>
              </ng-template>

              <ng-template #toggle>

                <button class="lom__operation-item" [attr.data-value]="item.operationName" [disabled]="item.disabled"
                        mat-stroked-button
                        [matTooltip]="item.tooltipContent"
                        matTooltipPosition="top">

                  <mat-icon>{{item.iconIdentifier}}</mat-icon>
                </button>
              </ng-template>
            </ng-container>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
    </ng-container>
`
})
export class LayerOperationsMenuComponent implements OnChanges {

  @Input()
  trigger: Array<Array<LayerOperationOption>> = [];

  @Input()
  canvas: fabric.Canvas;

  startHeight: number;

  altToggle = false;

  visibilityStates = {
    opacityControl: false
  };

  valueStates = {
    opacityControl: 1
  };

  @Input()
  availableMenuItems: Array<Array<LayerOperationOption>> = [];

  @Output()
  requestUpdateLayer: EventEmitter<any> = new EventEmitter<any>();

  constructor(private element: ElementRef) {}

  @HostBinding('@grow') get grow() {
    return {value: this.trigger, params: {startHeight: this.startHeight}};
  }

  setStartHeight() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  ngOnChanges(changes: SimpleChanges) {

    this.setStartHeight();

    // Menu Opens
    if (changes.trigger && (changes.trigger.currentValue > changes.trigger.previousValue)) {

      // @ts-ignore
      this.valueStates['opacityControl'] = this.canvas.getActiveObject().get('opacity');

      // Menu closes
    } else {

      this.setInitialVisibilityStates();
    }
  }

  getInitialVisibilityStates() {
    return {
      opacityControl: false
    };
  }

  setInitialVisibilityStates() {
    this.visibilityStates = this.getInitialVisibilityStates();
  }

  toggleVisibilityState(stateName: string) {

    const updatedStateProperty = {};

    updatedStateProperty[stateName] = !this.visibilityStates[stateName];

    // this.visibilityStates[stateName] = !this.visibilityStates[stateName];

    // Unset all other currently open dropdowns
    this.visibilityStates = {...this.getInitialVisibilityStates(), ...updatedStateProperty};
  }

  handleRequestUpdateLayer(e: any) {

    // Button click event
    if (e.hasOwnProperty('target')) {
      this.requestUpdateLayer.emit(e.target.dataset.value);
      return;
    }

    // Angular input field event, e.g. select dropdown action { value: xyz}
    this.requestUpdateLayer.emit(e);
  }
}
